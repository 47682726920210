import React, { useState, useEffect } from 'react';

const ReviewTab = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState(null);
  const [canReview, setCanReview] = useState(false); // Kiểm tra quyền đánh giá

  const user = JSON.parse(localStorage.getItem('user')); // Thông tin user từ localStorage
  const userId = user?.id;

  // 1. Lấy danh sách đánh giá
  useEffect(() => {
    fetch(`https://api.pethaven.website/api/products/${productId}/reviews`)
      .then((response) => response.json())
      .then((data) => setReviews(Array.isArray(data) ? data : []))
      .catch((err) => setError(err.message || 'Lỗi khi tải đánh giá'));
  }, [productId]);

  // 2. Kiểm tra người dùng đã mua sản phẩm hay chưa
  useEffect(() => {
    if (!userId) return;

    fetch(`https://api.pethaven.website/api/orders/success/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          // Nếu `data` là mảng đơn hàng
          const hasPurchased = data.some((order) =>
            order.products?.some((product) => product.id === productId)
          );
          setCanReview(hasPurchased);
        } else if (data.products) {
          // Nếu chỉ là một đơn hàng duy nhất
          const hasPurchased = data.products.some((product) => product.id === productId);
          setCanReview(hasPurchased);
        } else {
          setCanReview(false);
        }
      })
      .catch(() => setCanReview(false)); // Xử lý lỗi API
  }, [productId, userId]);

  // 3. Gửi đánh giá
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!canReview) {
      setError('Bạn chưa mua sản phẩm này nên không thể đánh giá.');
      return;
    }

    fetch(`https://api.pethaven.website/api/products/${productId}/reviews`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ comment, rating, user_id: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setReviews((prev) => [
            ...prev,
            { Comment: comment, Rating: rating, created_at: new Date(), user: { name: user?.name } },
          ]);
          setComment('');
          setRating(0);
        } else {
          setError(data.message || 'Lỗi khi gửi đánh giá.');
        }
      })
      .catch(() => setError('Lỗi kết nối tới server.'));
  };

  return (
    <div className="reviews mt-4">
      <h4 className="content-service">Bình luận</h4>
      {error && <p className="text-danger">{error}</p>}
      {reviews.map((review, index) => (
        <div className="review" key={index}>
          <h5>{review.user?.name || 'Người dùng'}</h5>
          <p>{new Date(review.created_at).toLocaleDateString()}</p>
          <p>{review.Comment}</p>
          <p>{'★'.repeat(review.Rating)}</p>
        </div>
      ))}

      {/* Form đánh giá */}
      {userId ? (
        canReview ? (
          <form onSubmit={handleSubmit}>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Nhập bình luận..."
                className="review-textarea"
              required
            />
            <input
              type="number"
                name="rating"
              min="1"
              max="5"
              value={rating}
              onChange={(e) => setRating(Number(e.target.value))}
              placeholder="Đánh giá từ 1-5"
               className="review-input"
              required
            /><br/>
            <button className="add-review mt-2" type="submit">Gửi đánh giá</button>
          </form>
        ) : (
          <p>Bạn chưa mua sản phẩm này nên không thể đánh giá.</p>
        )
      ) : (
        <p>Vui lòng đăng nhập để đánh giá sản phẩm.</p>
      )}
    </div>
  );
};

export default ReviewTab;
