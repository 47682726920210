import React, { useState, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useNavigate } from 'react-router-dom'; 
import { getServices, bookService, getPets } from '../../api/servicebooking';
import { useUser } from '../../context/UserContext';


const Booking = () => {
  const [services, setServices] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
 
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState(null);
  const [bookingTime, setBookingTime] = useState(''); // Thêm bookingTime
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [bookedTimes, setBookedTimes] = useState([]); 
  const navigate = useNavigate(); 
  const { user, setUser } = useUser();
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);


}
  useEffect(() => {
    // Lấy thông tin từ localStorage nếu user là null
    if (!user) {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setUser(storedUser);
        setPhone(storedUser.phone || '');
        setEmail(storedUser.email || '');
      } else {
        showSuccess('Bạn cần đăng nhập để có thể đặt dịch vụ');
        navigate('/login');
        return;
      }
    } else {
      setPhone(user.phone || '');
      setEmail(user.email || '');
    }


    const fetchData = async () => {
      try {
        const [servicesData, petsData] = await Promise.all([getServices(), getPets()]);
        setServices(servicesData);
        setPets(petsData);
      } catch (error) {
        console.error('Lỗi khi lấy dữ liệu:', error);
      }
    };
    fetchData();
  }, [user, setUser]);

  const handlePetChange = (e) => {
    const pet = pets.find((p) => p.id === parseInt(e.target.value));
    setSelectedPet(pet);
  };

  const handleServiceChange = (e) => {
    const service = services.find((s) => s.id === parseInt(e.target.value));
    setSelectedService(service);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPet || !selectedService || !bookingTime || !user || !user.id) {
      showError(['Vui lòng điền đầy đủ thông tin trước khi đặt lịch']);
      return;
    }

    const formattedDate = date ? date.toISOString().split('T')[0] : null;

    const bookingData = {
      user_id: user.id,
      pet_id: selectedPet?.id,
      service_id: selectedService?.id,
      booking_date: formattedDate,
      booking_time: bookingTime,
      phone,
      email,
    };

    try {
      const response = await bookService(bookingData);

      if (response.status === 'error') {
        setError(response.message);
      } else {
        showSuccess('Đặt lịch thành công!'); // Hiển thị alert
        setError(null);
        navigate('/BookingHistory'); // Chuyển hướng sang BookingHistory
      }
    } catch (err) {
      showError(['Khung giờ này đã được đặt hoặc ngày không phù hợp']);
    }
  };

  
  const isTimeBooked = (time) => bookedTimes.includes(time);
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100 booking-margin">
      <div className="row">
        <div className="col-md-6 image-section">
          <img src="../img/Let’s make.png" alt="Pet Image" className="rounded" />
        </div>
        <div className="col-md-6">
          <div className="form-box">
            <h2 className="form-title">Đặt lịch</h2>
            {message && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="pet-select" className="form-label">Chọn thú cưng</label>
                <select
                  id="pet-select"
                  className="form-select"
                  onChange={handlePetChange}
                >
                  <option value="">Chọn một thú cưng</option>
                  {pets.map((pet) => (
                    <option key={pet.id} value={pet.id}>
                      {pet.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="service-select" className="form-label">Chọn dịch vụ</label>
                <select
                  id="service-select"
                  className="form-select"
                  onChange={handleServiceChange}
                >
                  <option value="">Chọn một dịch vụ</option>
                  {services.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedService && (
               <p id='service-price' className="mt-2">
               Giá: {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(selectedService.price)}
           </p>
           
              )}

<div className="mb-3">
                <label htmlFor="phone" className="form-label">Số điện thoại *</label>
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder="Nhập số điện thoại"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email *</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Nhập email của bạn"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="birthdate" className="form-label">Chọn ngày</label>
                <Flatpickr
                  className="form-control"
                  id="birthdate"
                  value={date}
                  onChange={(selectedDates) => setDate(selectedDates[0])}
                />
              </div>

              <div className="mb-3">
    <label htmlFor="booking-time" className="form-label">Chọn giờ hẹn</label>
    <select
      id="booking-time"
      className="form-select"
      value={bookingTime}
      onChange={(e) => setBookingTime(e.target.value)}
    >
      <option value="">Chọn giờ hẹn</option>
      {['10:00', '12:00', '14:00', '16:00', '18:00'].map((time) => (
        <option
          key={time}
          value={time}
          style={{
            backgroundColor: isTimeBooked(time) ? 'gray' : 'white', 
            color: isTimeBooked(time) ? 'white' : 'black',
          }}
          disabled={isTimeBooked(time)} 
        >
          {time}
        </option>
      ))}
    </select>
  </div>

              <button type="submit" className="submit-btn">Gửi</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
