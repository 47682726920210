import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProductById, getRelatedProducts } from '../../api/product'; // Thêm API getRelatedProducts
import { CartContext } from '../../context/CartContext';
import Tabs from './Tabs';
import DescriptionTabs from './DescriptionTabs';
import ReviewTab from './ReviewTab';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]); // State lưu sản phẩm liên quan
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [activeTab, setActiveTab] = useState('description');
  const [quantityError, setQuantityError] = useState(''); // State lưu thông báo lỗi về số lượng
  const [averageRating, setAverageRating] = useState(null);
  const [totalReviews, setTotalReviews] = useState(0);
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await getProductById(id);
        setProduct(data.product);
  
        // Gọi API để lấy sản phẩm liên quan
        const relatedData = await getRelatedProducts(id);
        setRelatedProducts(relatedData);
  
        // Gọi API để lấy rating summary
        const ratingResponse = await fetch(`https://api.pethaven.website/api/products/${id}/reviews/summary`);
        const ratingData = await ratingResponse.json();
        setAverageRating(ratingData.average_rating);
        setTotalReviews(ratingData.total_reviews);
      } catch (err) {
        console.error('Error fetching product:', err);
        setError('Không tìm thấy sản phẩm.');
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);
  

  // Hàm kiểm tra và xử lý khi người dùng thay đổi số lượng
  const handleQuantityChange = (newQuantity) => {
    if (newQuantity <= 0) {
      setQuantity(1); // Đảm bảo không cho phép số lượng âm hoặc 0
      setQuantityError('Số lượng phải lớn hơn 0');
    } else if (newQuantity > product.stock_quantity) {
      setQuantity(newQuantity); // Cập nhật lại số lượng nếu hợp lệ
      setQuantityError('Số lượng trong kho không đủ');
    } else {
      setQuantity(newQuantity);
      setQuantityError(''); // Không có lỗi nếu số lượng hợp lệ
    }
  };

  const handleAddToCart = () => {
    if (quantity > product.stock_quantity) {
      setQuantityError('Số lượng trong kho không đủ');
    } else {
      addToCart(product, quantity);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <svg viewBox="25 25 50 50">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <div className="banner-service">
        <div className="banner-service-text">
          <h1 className="main-title">Sản phẩm</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Trang Chủ</Link></li>
              <li className="breadcrumb-item active-pro" aria-current="page">
                {product.name}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container detail-magin-top">
        <div className="row justify-content-lg-around product-details">
          <div className="col-md-5">
            <img
              src={`https://api.pethaven.website/images/products/${product.image}`}
              alt={product.name}
              className="product-image"
            />
          </div>

          <div className="col-md-5">
            <h3 className="spring-h3">{product.name}</h3>
            <p className="product-price"> {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.price)}</p>
            <p className="custom-description-cart-item">
    <span className="star-rating">
      {averageRating ? `${'★'.repeat(Math.round(averageRating))}` : '★★★★★'}
    </span> 
    {totalReviews > 0 
      ? ` (${totalReviews} khách hàng đánh giá)` 
      : ' (Chưa có đánh giá)'}
  </p>
            <p className="custom-description-cart-item">
              {product.description}
            </p>
            <p className="custom-description-cart-item">
              <b>Số lượng:</b> {product.stock_quantity === 0 ? 'Hết hàng' : product.stock_quantity}
            </p>

            <div className="quantity-wrapper mt-3 custom-description-cart-item">
              <label htmlFor="quantity">Chọn số lượng</label>
              <div className="quantity-control">
                <button className="btn-quantity" onClick={() => handleQuantityChange(Math.max(1, quantity - 1))}>-</button>
                <input type="number" id="quantity" value={quantity} readOnly />
                <button className="btn-quantity" onClick={() => handleQuantityChange(quantity + 1)}>+</button>
              </div>
            </div>

            {quantityError && <p className="error-message">{quantityError}</p>} {/* Hiển thị thông báo lỗi nếu có */}

            <button 
              className="add-to-cart-btn" 
              onClick={handleAddToCart}
              disabled={product.stock_quantity === 0 || quantity > product.stock_quantity} // Disable button nếu hết hàng hoặc số lượng vượt quá tồn kho
            >
              Thêm vào giỏ hàng
            </button>
          </div>
        </div>

        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 'description' ? <DescriptionTabs relatedProducts={relatedProducts} /> : <ReviewTab productId={product.id} />}
      </div>
    </>
  );
};

export default ProductDetail;
