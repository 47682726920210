import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchUsers } from '../../services/Admin/UserService';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  const getUsers = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchUsers(search, page, perPage);
      setUsers(data);
      setTotalPages(last_page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    getUsers();
  };

  const handleStatusToggle = async (user) => {
    const newStatus = user.is_action === 0 ? 1 : 0;
    const confirmationMessage = newStatus === 1
      ? 'Bạn có chắc chắn muốn khóa tài khoản này không?'
      : 'Bạn có chắc chắn muốn mở khóa tài khoản này không?';

    if (window.confirm(confirmationMessage)) {
      try {
        const response = await fetch(`https://api.pethaven.website/admin/user/update/${user.id}`, {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ is_action: newStatus })
        });

        const result = await response.json();
        if (response.ok) {
          showSuccess('Bạn đã cập nhật trạng thái thành công');
          getUsers(); // Refresh list after update
        } else {
          showError(['Đã xảy ra lỗi']);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
        alert('Lỗi kết nối API');
      }
    }
  };

  if (loading)
    <div className="loading-container">
      <svg viewBox="25 25 50 50" className="spinner">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </div>;
  if (users.length === 0) return <div>Không có khách hàng nào.</div>;

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content col-10">
        <div className="header">
          <div className="title">
            <h4 className="main-title-adminindex">Quản lý Người Dùng</h4>
          </div>
          <UserInfo />
        </div>
        <div className="search-bar-container">
          <div className="search-bar">
            <input
              className="form-control"
              placeholder="Tìm khách hàng"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button className="btn btn-dark" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="mt-4">
          <div className="card">
            <div className="card-header font-weight-bold">
              <form className="form-inline text-left">
                <Link to="/AddUser">
                  <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#addServiceModal">
                    Thêm người dùng
                  </button>
                </Link>
              </form>
            </div>
            <div className="card-body">

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên</th>
                    <th>Email</th>
                    <th>SDT</th>
                    <th>Địa chỉ</th>
                    <th>Quyền</th>
                    <th>Trạng thái</th>
                    <th className="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.id}>
                      <td>{(page - 1) * perPage + index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.address}</td>
                      <td>{user.role}</td>
                      <td>{user.is_action === 0 ? 'Hoạt động' : 'Ngừng hoạt động'}</td>
                      <td className="text-center">
                        <Link to={`/EditUser?id=${user.id}`}>
                          <i className="fas fa-eye text-success" />

                        </Link>
                        <button
                          onClick={() => handleStatusToggle(user)}
                          style={{
                            backgroundColor: '#ff9800', /* Màu cam */
                            color: '#fff',              /* Màu chữ trắng */
                            border: 'none',
                            marginLeft: '5px',           /* Xóa viền */
                            padding: '3px 6px',        /* Padding cho nút */
                            borderRadius: '1px',        /* Bo góc */
                            cursor: 'pointer'           /* Thay đổi con trỏ khi hover */
                          }}
                          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e68900'} /* Màu khi hover */
                          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#ff9800'}  /* Trở lại màu ban đầu */
                        >
                          {user.is_action === 0 ? (
                            <i className="fa fa-lock" aria-hidden="true"></i> // Icon khóa
                          ) : (
                            <i className="fa fa-unlock" aria-hidden="true"></i> // Icon mở khóa
                          )}
                        </button>


                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                      Previous
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="page-link">Page {page} of {totalPages}</span>
                  </li>
                  <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAdmin;
