import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';

const DescriptionTabs = ({ relatedProducts = [] }) => {
    const { addToCart } = useContext(CartContext);
  return (
    <div className="product-description mt-4">
      <h4 className="content-service">Mô tả</h4>
      <p className="custom-description-cart-item">
      Tại Pet Haven, chúng tôi cam kết mang đến những sản phẩm chất lượng nhất cho thú cưng của bạn. Các sản phẩm tại đây lựa chọn hoàn hảo cho những chú cún hoặc mèo yêu thích việc vui chơi và vận động. Sản phẩm này không chỉ là một món đồ chơi đơn giản, mà còn là một công cụ tuyệt vời để phát triển kỹ năng săn mồi tự nhiên của thú cưng.<br/>

<b>Cam kết chất lượng:</b> Mỗi món đồ  tại Pet Haven đều được thiết kế với chất liệu an toàn và bền bỉ, đảm bảo sức khỏe của thú cưng và giúp chúng vui chơi thoải mái mà không phải lo ngại về các hóa chất độc hại. 
      </p>

     
      {relatedProducts.length > 0 && (
        <div className="related-products mt-5">
          <h4 className="content-service">Sản phẩm liên quan</h4>
          <div className="row">
            {relatedProducts.map((product) => (
              <div className="col-md-3" key={product.id}>
                <div className="product-card">
                  <img src={`https://api.pethaven.website/images/products/${product.image}`} alt={product.name} />
                  <div className="product-title">
                    <Link to={`/product/${product.id}`}>{product.name}</Link>
                  </div>
                  <div className="product-sale-price">
                  {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.price)}  
                  </div>
                  <div className="product-rating justify-content-center">
                    <ul className="star-list">
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star"></i></li>
                      <li><i className="fa-solid fa-star-half-alt"></i></li>
                      <li><i className="fa-regular fa-star"></i></li>
                    </ul>
                    <span>(3.5)</span>
                  </div>
                  <div className="product-buttons">
                    <Link to={`/product/${product.id}`} className="btn-add-cart-product" onClick={() => addToCart(product)}>
                      Thêm vào giỏ hàng
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DescriptionTabs;
