import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchHotProducts, fetchNewProducts, getProducts, getProductsByCategory, getCategories, getpet, getProductsByPet } from '../../api/product';
import { CartContext } from '../../context/CartContext';

const Product = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState('');
  const [categorySlug, setCategorySlug] = useState('');
  const location = useLocation();
  const [petCategories, setPetCategories] = useState([]); // State lưu danh mục Pet
  const { addToCart } = useContext(CartContext);
  const [categories, setCategories] = useState([]);
  // Fetch sản phẩm mới
  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories([{ id: '', name: 'Tất cả', slug: '' }, ...data]); // Thêm mục "Tất cả"
    } catch (err) {
      console.error('Không thể tải danh mục:', err);
      setError('Không thể tải danh mục.');
    }
  };
  const fetchPetCategories = async () => {
    try {
      const data = await getpet();
      setPetCategories([{ id: '', name: 'Tất cả' }, ...data]);
    } catch (err) {
      console.error('Không thể tải danh mục pet:', err);
      setError('Không thể tải danh mục pet.');
    }
  };

  useEffect(() => {
    // Tải danh mục và sản phẩm khi component được render
    fetchCategories();
    fetchPetCategories(); // Gọi API lấy danh mục Pet
    if (!categorySlug) {
      fetchProducts(currentPage, sort);
    } else {
      fetchProductsByCategory(categorySlug, currentPage, sort);
    }
  }, [currentPage, sort, categorySlug])
  const NewProducts = async () => {
    try {
      setLoading(true);
      const data = await fetchNewProducts();
      setProducts(data.data); // Đặt sản phẩm mới
      setTotalPages(1); // Sản phẩm mới không có phân trang
    } catch (err) {
      setError('Không thể tải sản phẩm mới.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch sản phẩm bán chạy
  const HotProducts = async () => {
    try {
      setLoading(true);
      const data = await fetchHotProducts();
      setProducts(data.data); // Đặt sản phẩm bán chạy
      setTotalPages(1); // Sản phẩm hot không có phân trang
    } catch (err) {
      setError('Không thể tải sản phẩm bán chạy.');
    } finally {
      setLoading(false);
    }
  };

  // Khi user nhấn vào liên kết
  const handleFetchNew = () => {
    NewProducts();
  };

  const handleFetchHot = () => {
    HotProducts();
  };
  const fetchProducts = async (page, sortOption, search) => {
    try {
      setLoading(true);
      const data = await getProducts(page, sortOption, 9, search);
      setProducts(data.data.data);
      console.log(data.data.data);

      setTotalPages(data.data.last_page);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Không thể tải sản phẩm.');
    } finally {
      setLoading(false);
    }
  };



  const fetchProductsByCategory = async (slug, page, sortOption) => {
    try {
      setLoading(true);
      const data = await getProductsByCategory(slug, page, sortOption);

      // Đảm bảo lấy đúng danh sách sản phẩm từ `data.products.data`
      setProducts(data.products.data);

      // Đảm bảo lấy số trang cuối cùng từ `data.products.last_page`
      setTotalPages(data.products.last_page);
    } catch (err) {
      console.error('Error fetching products by category:', err);
      setError('Không thể tải sản phẩm theo danh mục.');
    } finally {
      setLoading(false);
    }
  };
  const fetchProductsByPet = async (petId, page, sortOption) => {
    try {
      setLoading(true);
      const data = await getProductsByPet(petId, page, sortOption);
      setProducts(data.products.data);
      setTotalPages(data.totalPages); // Số trang phân trang (nếu có)
    } catch (err) {
      console.error('Không thể tải sản phẩm theo Pet:', err);
      setError('Không thể tải sản phẩm theo Pet.');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');

    if (query) {
      // Nếu có truy vấn tìm kiếm, bạn có thể gọi API với query
      fetchProducts(currentPage, sort, query); // Gọi hàm fetchProducts với query
    } else if (categorySlug) {
      fetchProductsByCategory(categorySlug, currentPage, sort);
    } else {
      fetchProducts(currentPage, sort);
    }
  }, [currentPage, sort, categorySlug, location.search]);


  // Xử lý khi người dùng thay đổi sắp xếp
  const handleSortChange = (sortOption) => {
    setSort(sortOption);
    setCurrentPage(1);

    if (categorySlug) {
      fetchProductsByPet(categorySlug, 1, sort);
      fetchProductsByCategory(categorySlug, 1, sortOption);
    } else {
      fetchProducts(1, sortOption);
    }
  };

  // Xử lý khi người dùng thay đổi trang
  const handlePageChange = (page) => {
    setCurrentPage(page);

    if (categorySlug) {
      fetchProductsByPet(categorySlug, page, sort);
      fetchProductsByCategory(categorySlug, page, sort);

    } else {
      fetchProducts(page, sort);
    }
  };
  const handleCategoryChange = (categoryId) => {
    setCategorySlug(categoryId); // Đổi tên thành categoryId để rõ ràng hơn
    setCurrentPage(1);
    if (categoryId) {
      fetchProductsByCategory(categoryId, 1, sort);
    } else {
      fetchProducts(1, sort);
    }
  };
  const handlePetCategoryChange = (petId) => {
    setCategorySlug(''); // Xóa slug danh mục hiện tại
    setCurrentPage(1);
    if (petId) {
      console.log('petid', petId);
      fetchProductsByPet(petId, 1, sort);
    } else {
      fetchProducts(1, sort);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <svg viewBox="25 25 50 50" className="spinner">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
    );
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <main>
      <div className="banner-service">
        <div className="banner-service-text">
          <h1 className="main-title">Sản phẩm</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Trang Chủ</Link>
              </li>
              <li className="breadcrumb-item active-pro" aria-current="page">
                Sản phẩm
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container mt-4 magin-product">
        <div className="row">
          <div className="col-md-3">
            <div className="sidebar">
              <h5 className="group-h5">Tùy chọn</h5>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <Link onClick={handleFetchNew}>Sản phẩm mới nhất</Link>
                </li>
                <li className="list-group-item">
                  <Link onClick={handleFetchHot}>Sản phẩm bán chạy</Link>
                </li>
                <li className="list-group-item">
                  <Link onClick={() => handleSortChange('name_asc')}>
                    Sắp xếp từ A - Z
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link onClick={() => handleSortChange('name_desc')}>
                    Sắp xếp từ Z - A
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link onClick={() => handleSortChange('price_asc')}>
                    Sắp xếp giá cao dần
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link onClick={() => handleSortChange('price_desc')}>
                    Sắp xếp giá thấp dần
                  </Link>
                </li>
              </ul>

              <h5 className="group-h5">Danh mục</h5>
              <ul className="list-group mb-4">
                {categories.map((category) => (
                  <li key={category.id} className="list-group-item">
                    <Link
                      onClick={() => handleCategoryChange(category.id)} // Truyền ID thay vì slug
                      className={categorySlug === category.id ? 'active' : ''}
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <h5 className="group-h5">Loại Pet</h5>
              <ul className="list-group mb-4">
                {petCategories.map((pet) => (
                  <li key={pet.id} className="list-group-item">
                    <Link
                      onClick={() => handlePetCategoryChange(pet.id)}
                      className={categorySlug === pet.id ? 'active' : ''}
                    >
                      {pet.name}
                    </Link>
                  </li>
                ))}
              </ul>

            </div>
          </div>

          <div className="col-md-9">
            <h5 className="group-h5">{products ? products.length : 0} sản phẩm</h5>

            <div className="row">
              {products && products.length > 0 ? (
                products.map((product) => (
                  <div className="col-md-4" key={product.id}>
                    <div className="product-card">
                      <img src={`https://api.pethaven.website/images/products/${product.image}`} alt={product.name} />
                      <div className="product-title">
                        <Link to={`/product/${product.id}`}>{product.name}</Link>
                      </div>
                      <div className="product-price">
                        {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.price)}
                      </div>
                      <div className="product-stock">
                        {product.stock_quantity > 0 ? 'Còn hàng' : 'Hết hàng'}
                      </div>
                      <div className="product-buttons">
                        <Link className="btn-add-cart-product" onClick={() => addToCart(product)}>
                          Thêm vào giỏ hàng
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Không có sản phẩm nào để hiển thị.</p>
              )}

            </div>

            <nav aria-label="Page navigation example" className="mt-4">
              <ul className="pagination justify-content-right">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Product;
