import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const OrderReturn = () => {
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        const storedOrderDetails = localStorage.getItem('orderDetails');
        console.log(storedOrderDetails);

        if (storedOrderDetails) {
            try {
                setOrderDetails(JSON.parse(storedOrderDetails));
            } catch (error) {
                console.error("Lỗi phân tích dữ liệu từ localStorage:", error);
            }
        }
    }, []);

    if (!orderDetails) {
        return <p>Không tìm thấy thông tin đơn hàng.</p>;
    }

    // if (!orderDetails.items || orderDetails.items.length === 0) {
    //     return (
    //         <div className="order-return-container">
    //             <h1>Đặt hàng thành công! 🎉</h1>
    //             <p>Không có sản phẩm nào trong đơn hàng.</p>
    //         </div>
    //     );
    // }

    return (
        <div className="order-return-container">
            <div className="order-success">
                <h1>Đặt hàng thành công! 🎉</h1>
                <p>
                    Cảm ơn bạn đã mua hàng. <Link to="/OrderHistory">Theo dõi đơn hàng</Link>.
                </p>
            </div>
            <div className="order-details">
                <h2>Chi Tiết Đơn Hàng</h2>
                <table className="order-table">
                    <thead>
                        <tr>
                            <th>Sản phẩm</th>
                            <th className="text-right">Thanh toán</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetails.items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td> {/* Hiển thị ID sản phẩm */}
                                <td className="text-right">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.price)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>Tổng Số Phụ:</td>
                            <td className="text-right">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(orderDetails.total_money)}</td>
                        </tr>
                        <tr>
                            <td className="payment-method">Phương Thức Thanh Toán:</td>
                            <td className="text-right">{orderDetails.payment_id === 1 ? 'MoMo' : 'Tiền mặt'}</td>
                        </tr>
                        <tr className="total-row">
                            <td>Tổng Cộng:</td>
                            <td className="text-right">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(orderDetails.total_money)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderReturn;
