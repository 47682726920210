import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './page/users/Home';
import Footer from './components/Footer';
import UserLayout from './page/users';
import AdminLayout from './page/admin';
import Login from './page/users/Login';
import Register from './page/users/Register';
import Dashboard from './page/admin/Dashboard';
import ProductAdmin from './page/admin/ProductAdmin';
import AddProducts from './page/admin/AddProducts';
import CategoryAdmin from './page/admin/CategoryAdmin';
import AddCategory from './page/admin/AddCategory';
import EditOrderAdmin from './page/admin/EditOrderAdmin';
import CommentAdmin from './page/admin/CommentAdmin';
import ServiceAdmin from './page/admin/ServiceAdmin';
import ServiceBooking from './page/admin/ServiceBooking';
import EditServiceBooking from './page/admin/EditServiceBooking';
import AddService from './page/admin/AddService';
import CategoryNewsAdmin from './page/admin/CategoryNewsAdmin';
import AddCategoryNews from './page/admin/AddCategoryNews';
import NewAdmin from './page/admin/NewAdmin';
import AddNewsAdmin from './page/admin/AddNewsAdmin';
import OderAdmin from './page/admin/OderAdmin';
import Warehouse from './page/admin/Warehouse';
import Product from './page/users/Product';
import ProductDetail from './page/users/ProductDetail';
import UserAdmin from './page/admin/UserAdmin';
import EditUser from './page/admin/EditUser';
import ThongKeSanPhamBan from './page/admin/ThongKeSanPhamBan';
import PetAdmin from './page/admin/PetAdmin';
import AddPetAdmin from './page/admin/AddPetAdmin';
import Cart from './page/users/Cart';
import { CartProvider } from './context/CartContext';
import AdminRoute from './services/Admin/Adminrouter'; // HOC bảo vệ các route admin
import Service from './page/users/Service';
import ServiceDetail from './page/users/ServiceDetail';
import News from './page/users/News';
import NewsDesciption from './page/users/NewsDesciption';
import Booking from './page/users/Booking';
import BookingHistory from './page/users/BookingHistory';
import WarehouseEntry from './page/admin/WarehouseEntry';

import { UserProvider } from './context/UserContext';
import Personal from './page/users/Personal';

import BaoCaoDoanhThu from './page/admin/BaoCaoDoanhThu';
import BaoCaoDoanhThuTotal from './page/admin/BaoCaoDoanhThuTotal';

import Order from './page/users/Order';
import Otp from './page/users/Otp';
import ResetPassword from './page/users/ResetPassword';
import GoogleAuth from './page/users/GoogleAuth';
import OrderHistory from './page/users/OrderHistory';
import OderHistoryXn from './page/users/OderHistoryXn';
import OderHistoryShip from './page/users/OderHistoryShip';
import OderHistoryDn from './page/users/OderHistoryDn';
import OderHistoryHuy from './page/users/OderHistoryHuy';

import OderReturn from './page/users/OderReturn';
import OderHistoryReturn from './page/users/OderHistoryReturn';
import LienHe from './page/users/LienHe';
import GioiThieu from './page/users/GioiThieu';
import AddUser from './page/admin/AddUser';
import OderReturnmomo from './page/users/OderReturnmomo';
import Inventory from './page/admin/Inventory';
function App() {
  return (
    <UserProvider>
      <CartProvider>
        <Router>
          <Routes>
            {/* Các route cho User */}
            <Route path="/" element={<UserLayout><Home /></UserLayout>} />
            <Route path="/login" element={<UserLayout><Login /></UserLayout>} />
            <Route path="/register" element={<UserLayout><Register /></UserLayout>} />
            <Route path="/Product" element={<UserLayout><Product /></UserLayout>} />
            <Route path="/product/:id" element={<UserLayout><ProductDetail /></UserLayout>} />
            <Route path="/Review/:id" element={<UserLayout><ProductDetail /></UserLayout>} />
            <Route path="/Cart" element={<UserLayout><Cart /></UserLayout>} />
            <Route path="/Service" element={<UserLayout><Service /></UserLayout>} />

            <Route path="/Service/:id" element={<UserLayout><ServiceDetail /></UserLayout>} />
            <Route path="/News" element={<UserLayout><News /></UserLayout>} />
            <Route path="/NewsDesciption/:id" element={<UserLayout><NewsDesciption /></UserLayout>} />
            <Route path="/ServiceBooking" element={<UserLayout><Booking /></UserLayout>} />
            <Route path="/Personal" element={<UserLayout><Personal /></UserLayout>} />


            <Route path="/Service/:id" element={<UserLayout><ServiceDetail /></UserLayout>} />
            <Route path="/News" element={<UserLayout><News /></UserLayout>} />
            <Route path="/NewsDesciption/:id" element={<UserLayout><NewsDesciption /></UserLayout>} />
            <Route path="/ServiceBooking" element={<UserLayout><Booking /></UserLayout>} />
            <Route path="/Personal" element={<UserLayout><Personal /></UserLayout>} />
            <Route path="/BookingHistory" element={<UserLayout><BookingHistory /></UserLayout>} />
            <Route path="/Order" element={<UserLayout><Order /></UserLayout>} />
            <Route path="/Otp" element={<UserLayout><Otp /></UserLayout>} />
            <Route path="/ResetPassword" element={<UserLayout><ResetPassword /></UserLayout>} />
            <Route path="/GoogleAuth" element={<UserLayout><GoogleAuth /></UserLayout>} />
            <Route path="/Service/:id" element={<UserLayout><ServiceDetail /></UserLayout>} />
            <Route path="/News" element={<UserLayout><News /></UserLayout>} />
            <Route path="/NewsDesciption/:id" element={<UserLayout><NewsDesciption /></UserLayout>} />
            <Route path="/ServiceBooking" element={<UserLayout><Booking /></UserLayout>} />
            <Route path="/OrderHistory" element={<UserLayout><OrderHistory /></UserLayout>} />
            <Route path="/OderHistoryXn" element={<UserLayout><OderHistoryXn /></UserLayout>} />
            <Route path="/OderHistoryShip" element={<UserLayout><OderHistoryShip /></UserLayout>} />
            <Route path="/OderHistoryDn" element={<UserLayout><OderHistoryDn /></UserLayout>} />
            <Route path="/OderHistoryHuy" element={<UserLayout><OderHistoryHuy /></UserLayout>} />
            <Route path="/OderReturn" element={<UserLayout><OderReturn /></UserLayout>} />
            <Route path="/OderHistoryReturn" element={<UserLayout><OderHistoryReturn /></UserLayout>} />
            <Route path="/LienHe" element={<UserLayout><LienHe /></UserLayout>} />
            <Route path="/gioi-thieu" element={<UserLayout><GioiThieu /></UserLayout>} />

            <Route path="/OderReturnmomo/:orderId" element={<UserLayout><OderReturnmomo /></UserLayout>} />
            {/* Các route cho Admin được bảo vệ bởi AdminRoute */}
            <Route path="/admin" element={<AdminRoute><AdminLayout /></AdminRoute>} />
            <Route path="/dashboard" element={<AdminRoute><AdminLayout><Dashboard /></AdminLayout></AdminRoute>} />
            <Route path="/baocaodoanhthuchitiet" element={<AdminRoute><AdminLayout><BaoCaoDoanhThu /></AdminLayout></AdminRoute>} />
            <Route path="/baocaodoanhthuTong" element={<AdminRoute><AdminLayout><BaoCaoDoanhThuTotal /></AdminLayout></AdminRoute>} />
            <Route path="/ProductAdmin" element={<AdminRoute><AdminLayout><ProductAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/add-product" element={<AdminRoute><AdminLayout><AddProducts /></AdminLayout></AdminRoute>} />
            <Route path="/edit-product" element={<AdminRoute><AdminLayout><AddProducts /></AdminLayout></AdminRoute>} />
            <Route path="/add-category" element={<AdminRoute><AdminLayout><AddCategory /></AdminLayout></AdminRoute>} />
            <Route path="/CategoryAdmin" element={<AdminRoute><AdminLayout><CategoryAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/CommentAdmin" element={<AdminRoute><AdminLayout><CommentAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/ServiceAdmin" element={<AdminRoute><AdminLayout><ServiceAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/ServiceBookingAdmin" element={<AdminRoute><AdminLayout><ServiceBooking /></AdminLayout></AdminRoute>} />
            <Route path="/edit-servicebooking" element={<AdminRoute><AdminLayout><EditServiceBooking /></AdminLayout></AdminRoute>} />
            <Route path="/add-service" element={<AdminRoute><AdminLayout><AddService /></AdminLayout></AdminRoute>} />
            <Route path="/edit-service" element={<AdminRoute><AdminLayout><AddService /></AdminLayout></AdminRoute>} />
            <Route path="/CategoryNewsAdmin" element={<AdminRoute><AdminLayout><CategoryNewsAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/add-categorynew" element={<AdminRoute><AdminLayout><AddCategoryNews /></AdminLayout></AdminRoute>} />
            <Route path="/edit-categorynew" element={<AdminRoute><AdminLayout><AddCategoryNews /></AdminLayout></AdminRoute>} />
            <Route path="/NewAdmin" element={<AdminRoute><AdminLayout><NewAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/add-news" element={<AdminRoute><AdminLayout><AddNewsAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/edit-news" element={<AdminRoute><AdminLayout><AddNewsAdmin /></AdminLayout></AdminRoute>} />

            <Route path="/edit-order" element={<AdminRoute><AdminLayout><EditOrderAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/add-stockin" element={<AdminRoute><AdminLayout><WarehouseEntry /></AdminLayout></AdminRoute>} />
            <Route path="/edit-stockin" element={<AdminRoute><AdminLayout><WarehouseEntry /></AdminLayout></AdminRoute>} />
            <Route path="/OderAdmin" element={<AdminRoute><AdminLayout><OderAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/Warehouse" element={<AdminRoute><AdminLayout><Warehouse /></AdminLayout></AdminRoute>} />
            <Route path="/UserAdmin" element={<AdminRoute><AdminLayout><UserAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/AddUser" element={<AdminRoute><AdminLayout><AddUser /></AdminLayout></AdminRoute>} />
            <Route path="/EditUser" element={<AdminRoute><AdminLayout><EditUser /></AdminLayout></AdminRoute>} />
            <Route path="/ThongKeSanPhamBan" element={<AdminRoute><AdminLayout><ThongKeSanPhamBan /></AdminLayout></AdminRoute>} />
            <Route path="/PetAdmin" element={<AdminRoute><AdminLayout><PetAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/AddPetAdmin" element={<AdminRoute><AdminLayout><AddPetAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/EditPetAdmin" element={<AdminRoute><AdminLayout><AddPetAdmin /></AdminLayout></AdminRoute>} />
            <Route path="/edit-category" element={<AdminRoute><AdminLayout><AddCategory /></AdminLayout></AdminRoute>} />
            <Route path="/Inventory" element={<AdminRoute><AdminLayout><Inventory /></AdminLayout></AdminRoute>} />
          </Routes>
        </Router>
      </CartProvider>
    </UserProvider>
  );
}

export default App;
