import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const OrderAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  // Trạng thái có thể thay đổi
  const statuses = {
    pending: 'Chờ xử lý',
    prepare: 'Đã xác nhận',
    shipping: 'Đang giao hàng',
    success: 'Thành công',
    cancle: 'Hủy',

  };

  // API lấy danh sách đơn hàng
  const fetchOrders = async (search = '', page = 1, perPage = 10, fromDate = '', toDate = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.pethaven.website/admin/order`, {
        params: {
          search,
          page,
          per_page: perPage,
          from_date: fromDate,
          to_date: toDate,
        },
      });
      setOrders(response.data.data);
      setTotalPages(response.data.last_page);
      setLoading(false);
    } catch (error) {
      console.error('Lỗi lấy danh sách đơn hàng:', error);
      setLoading(false);
    }
  };

  // Xử lý trạng thái không hợp lệ
  const isValidStatusChange = (currentStatus, newStatus) => {
    const invalidChanges = {
      pending: ['pending',],
      prepare: ['pending'],
      shipping: ['pending', 'prepare', 'cancle'],
      success: ['pending', 'prepare', 'shipping', 'cancle'],
      cancle: ['pending', 'prepare', 'shipping', 'success', 'return'],

    };

    return !invalidChanges[currentStatus]?.includes(newStatus);
  };

  // Hàm cập nhật trạng thái đơn hàng
  const updateOrderStatus = async (orderId, currentStatus, newStatus) => {
    if (!isValidStatusChange(currentStatus, newStatus)) {
      showError(['Thay đổi trạng thái không hợp lệ!']);
      return;
    }

    if (window.confirm('Bạn có chắc chắn muốn thay đổi trạng thái đơn hàng không?')) {
      try {
        await axios.put(`https://api.pethaven.website/admin/order/update/${orderId}`, { status: newStatus });
        showSuccess('Cập nhật trạng thái thành công!');
        fetchOrders(searchTerm, page, perPage, fromDate, toDate); // Làm mới danh sách đơn hàng
      } catch (error) {
        console.error('Lỗi cập nhật trạng thái:', error);
        showError(['Có lỗi xảy ra, vui lòng thử lại sau.']);
      }
    }
  };

  useEffect(() => {
    fetchOrders(searchTerm, page, perPage, fromDate, toDate);
  }, [searchTerm, page, perPage, fromDate, toDate]);

  // Xử lý tìm kiếm
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    fetchOrders(searchTerm, 1, perPage, fromDate, toDate);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchOrders(searchTerm, newPage, perPage, fromDate, toDate);
  };

  if (loading) return <div className="loading-container">Đang tải...</div>;

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
          <div className="header">
            <h4 className="main-title-adminindex">Danh sách đơn hàng</h4>
            <div className="d-flex align-items-center">
              <div className="search-bar">
                <input
                  placeholder="Tìm kiếm"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <button onClick={handleSearch}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <UserInfo />
          </div>

          <section className="table-section">
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Mã Đơn Hàng</th>
                  <th>Tên Khách Hàng</th>
                  <th>Ngày Đặt</th>
                  <th>Tổng Tiền</th>
                  <th>Trạng Thái</th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order, index) => (
                    <tr key={order.id}>
                      <td>{(page - 1) * perPage + index + 1}</td>
                      <td>#{order.id}</td>
                      <td>{order.user_fullname}</td>
                      <td>{new Date(order.created_at).toLocaleDateString()}</td>
                      <td> {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(order.total_money.toLocaleString())}</td>

                      <td>
                        <select class="form-select"
                          value={order.status}
                          onChange={(e) =>
                            updateOrderStatus(order.id, order.status, e.target.value)
                          }
                        >
                          {Object.entries(statuses).map(([key, label]) => (
                            <option
                              key={key}
                              value={key}
                              disabled={!isValidStatusChange(order.status, key)} // Disable nếu không hợp lệ
                            >
                              {label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <Link to={`/edit-order/?id=${order.id}`}>
                          <i className="fas fa-edit text-success" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">Không có đơn hàng nào</td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>

          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                  Trước
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">
                  Trang {page} / {totalPages}
                </span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                  Tiếp
                </button>
              </li>
            </ul>
          </nav>
        </main>
      </div>
    </div>
  );
};

export default OrderAdmin;
