import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Nhập useNavigate
import { register } from '../../services/AthSevices'; // Giả định bạn có một file api.js để thực hiện API call

const Register = () => {
  const [name, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate(); // Khởi tạo useNavigate
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

    // Thêm sự kiện click để đóng thông báo khi nhấn vào nút "X"
    const closeButton = errorContainer.querySelector(".close-btn");
    closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId); // Hủy timeout nếu người dùng tự đóng
        errorContainer.remove();
    });
}
  const handleRegister = async (event) => {
    event.preventDefault();

    const body = { name, email, password, password_confirmation, phone, address };

    try {
      const response = await register(body);
      console.log(response);

      // Nếu đăng ký thành công, hiển thị thông báo và chuyển hướng đến trang login
      showSuccess('Bạn đã đăng ký thành công ');
      navigate('/login', { state: { successMessage: 'Đăng ký thành công. Vui lòng đăng nhập.' } });
    } catch (error) {
      showError('Đăng ký không thành công');

      // Kiểm tra xem có lỗi từ server trả về hay không
      if (error.response && error.response.data.errors) {
        const errors = error.response.data.errors;
        setErrorMessage(Object.values(errors).flat().join(', ')); // Kết hợp tất cả thông báo lỗi
      } else {
        showError('Đăng ký thất bại. Vui lòng thử lại.'); // Thông báo lỗi chung
      }
    }
  };

  return (
    <main>
      <div className="wrap">
        <div className="containerRegister" id="container">
          <div className="form-container sign-in-container">
            <form onSubmit={handleRegister} className='formRegigter'>
              <h1 className="headH1">ĐĂNG KÝ</h1>

              <input
                type="text"
                placeholder="Username"
                 className="username"
                value={name}
                onChange={(e) => setUserName(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="email"
                placeholder="Email"
                 className="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="text"
                placeholder="Số điện thoại"
                 className="username"
                value={phone}
                onChange={(e) => setPhone(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="text"
                placeholder="Địa chỉ"
                 className="username"
                value={address}
                onChange={(e) => setAddress(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="password"
                placeholder="Password"
                 className="username"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Cập nhật state
                required
              />
              <input
                type="password"
                placeholder="Confirm Password"
                 className="username"
                value={password_confirmation}
                onChange={(e) => setConfirmPassword(e.target.value)} // Cập nhật state
                required
              />
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} {/* Hiển thị thông báo lỗi */}
              <button type="submit" className="buttonLogin">ĐĂNG KÝ</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlayRegister">
           
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
