// Cart.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';

const Cart = () => {
  const { cart, updateQuantity, removeFromCart } = useContext(CartContext);

  const handleQuantityChange = (productId, event) => {
    const quantity = parseInt(event.target.value);
    if (quantity > 0) {
      updateQuantity(productId, quantity);
    }
  };

  return (
    <div>
      <div className="banner-service">
        <div className="banner-service-text">
          <h1 className="main-title">Giỏ hàng</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Trang Chủ</Link>
              </li>
              <li className="breadcrumb-item active-pro" aria-current="page">
                Giỏ hàng
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container mt-5">
        <h2 className="mb-4 main-heading">Giỏ hàng</h2>
        
       
        {cart.length === 0 ? (
          <p>Không có sản phẩm nào trong giỏ hàng.</p>
        ) : (
          <>
            <table className="table table-bordered mt-4">
              <thead>
                <tr className="content-cart">
                  <th>Sản phẩm</th>
                  <th>Giá</th>
                  <th>Số lượng</th>
                  <th>Tổng phụ</th>
                  <th>Xóa</th>
                </tr>
              </thead>
              <tbody className="custom-description-cart-item">
                {cart.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <img src={`../img1/${item.image}`} alt={item.name} width={50} /> {item.name}
                    </td>
                    <td> {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.price.toLocaleString())}</td>
                    <td>
                      <input
                        type="number"
                        value={item.quantity}
                        min="1"
                        className="form-control"
                        style={{ width: '60px' }}
                        onChange={(e) => handleQuantityChange(item.id, e)}
                      />
                    </td>
                    <td>{(item.price * item.quantity).toLocaleString()} đ</td>
                    <td>
                      <button className="btn btn-danger" onClick={() => removeFromCart(item.id)}>
                        Xóa
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-4">
              <h4 className="content-cart">Tổng giỏ hàng</h4>
              <table className="table custom-description-cart-item">
                <tbody>
                  <tr>
                    <td className="td-cart">Tổng phụ</td>
                    <td className="td-cart">
                      {cart.reduce((total, item) => total + item.price * item.quantity, 0).toLocaleString()} đ
                    </td>
                  </tr>
                  <tr>
                    <td className="td-cart">Tổng</td>
                    <td className="td-cart">
                      {cart.reduce((total, item) => total + item.price * item.quantity, 0).toLocaleString()} đ
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-start" style={{ marginBottom: '50px' }}>
             <Link to="/Order"> <button className="custom-btn-pay">Tiến hành thanh toán</button></Link>  
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;
