import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation(); // Lấy đường dẫn hiện tại

    return (
        <div className="col-md-2 sidebar sidebar-after">

            <div className="text-center mb-4">
                <Link to="/">
                    <img alt="" height="100" src="../img/logo-removebg.png" width="100" />
                </Link>
                <h4 className="main-heading-admin">PET HEAVEN</h4>

            </div>

            <Link to="/Dashboard" className={location.pathname === "/Dashboard" ? "active" : ""}>
                <i className="fa-solid fa-gauge-high" style={{ color: '#f7f7f7' }} /> Dashboard
            </Link>
            <Link to="/baocaodoanhthuchitiet" className={location.pathname === "/baocaodoanhthuchitiet" ? "active" : ""}>
                <i className="fas fa-envelope" style={{ color: '#f7f7f7' }} /> Báo cáo doanh thu Chi tiết
            </Link>

            <Link to="/CategoryAdmin" className={location.pathname === "/CategoryAdmin" ? "active" : ""}>
                <i className="fa-solid fa-list" style={{ color: '#f7f7f7' }} /> Quản lý danh mục
            </Link>
            <Link to="/ProductAdmin" className={location.pathname === "/ProductAdmin" ? "active" : ""}>
                <i className="fas fa-box" style={{ color: '#f7f7f7' }} /> Quản lý sản phẩm
            </Link>
            <Link to="/ServiceAdmin" className={location.pathname === "/ServiceAdmin" ? "active" : ""}>
                <i className="fa-brands fa-servicestack" style={{ color: '#f7f7f7' }} /> Quản lý dịch vụ
            </Link>
            <Link to="/NewAdmin" className={location.pathname === "/NewAdmin" ? "active" : ""}>
                <i className="fa-solid fa-newspaper" style={{ color: '#f7f7f7' }} /> Quản lý tin tức
            </Link>
            <Link to="/CategoryNewsAdmin" className={location.pathname === "/CategoryNewsAdmin" ? "active" : ""}>
                <i className="fa-solid fa-list" style={{ color: '#f7f7f7' }} /> Quản lý thể loại tin tức
            </Link>
            <Link to="/PetAdmin" className={location.pathname === "/PetAdmin" ? "active" : ""}>
                <i className="fas fa-paw" style={{ color: '#f7f7f7' }} /> Quản lý thú cưng
            </Link>
            <Link to="/UserAdmin" className={location.pathname === "/UserAdmin" ? "active" : ""}>
                <i className="fas fa-users" style={{ color: '#f7f7f7' }} /> Quản lý khách hàng
            </Link>
            <Link to="/CommentAdmin" className={location.pathname === "/CommentAdmin" ? "active" : ""}>
                <i className="fa-solid fa-comment" style={{ color: '#f7f7f7' }} /> Quản lý bình luận
            </Link>
            <Link to="/OderAdmin" className={location.pathname === "/OderAdmin" ? "active" : ""}>
                <i className="fa-solid fa-cart-shopping fa-sm" style={{ color: '#f7f7f7' }} /> Quản lý đơn hàng
            </Link>


            <Link to="/ServiceBookingAdmin" className={location.pathname === "/ServiceBookingAdmin" ? "active" : ""}>
                <i className="fa-solid fa-address-book" style={{ color: '#f7f7f7' }} /> Quản lý đặt lịch
            </Link>

            <Link to="/Warehouse" className={location.pathname === "/Warehouse" ? "active" : ""}>
                <i className="fas fa-warehouse" style={{ color: '#f7f7f7' }} /> Quản lý nhập kho
            </Link>

            <Link to="/Inventory" className={location.pathname === "/Warehouse" ? "active" : ""}>
                <i className="fas fa-warehouse" style={{ color: '#f7f7f7' }} /> Quản lý tồn kho
            </Link>
        </div>
    );
};

export default Sidebar;
