import React, { useEffect, useState } from 'react';
import { fetchInventoryItems } from '../../services/Admin/ServicesService'; // Giả định bạn có service tương ứng
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin';

const Inventory = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getItems = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchInventoryItems(search, page, perPage);
      setItems(data);
      setTotalPages(last_page);
    } catch (error) {
      console.error('Error fetching warehouse items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItems();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchInput);
    setPage(1);
  };



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            <div className="header">
              <div className="text-left">
                <h4 className="main-title-adminindex">Danh sách tồn kho</h4>
              </div>
              <UserInfo />
            </div>

            {/* Search bar section */}
            <div className="search-bar-container d-flex align-items-center justify-content-between">
              <div className="search-bar">
                <input
                  className="form-control"
                  placeholder="Tìm tên sản phẩm"
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button className="btn btn-dark" onClick={handleSearch}>
                  <i className="fas fa-search"></i>
                </button>
              </div>

            </div>

            {/* Table section */}
            <section className="table-section mt-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên Sản Phẩm</th>
                    <th>Số Lượng</th>

                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(page - 1) * perPage + index + 1}</td>
                      <td>{item.product_name}</td>

                      <td>{item.total_quantity}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </section>

            {/* Pagination Controls */}
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                    Previous
                  </button>
                </li>
                <li className="page-item">
                  <span className="page-link">Page {page} of {totalPages}</span>
                </li>
                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </main>
        </div>
      </div>
    </>
  );
};

export default Inventory;
