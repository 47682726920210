import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Sử dụng useNavigate thay vì useHistory

const UserInfo = () => {
    const [userName, setUserName] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const navigate = useNavigate();  // Khai báo hook navigate

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            console.log('user:', user);
            setUserName(user.name);
            setUserAvatar(`http://localhost:8000/images/user/${user.img}` || '../../../img/avatar-gt1.jpg'); // Đường dẫn mặc định nếu không có ảnh
        }
    }, []);

    // Hàm logout
    const handleLogout = () => {
        localStorage.removeItem('user');  // Xóa dữ liệu user khỏi localStorage
        navigate('/');  // Quay về trang chủ
    };

    return (
        <div className="user-info">
            <i className="fas fa-bell" style={{ marginRight: '20px' }}></i>
            <i className="fas fa-cog" style={{ marginRight: '20px' }}></i>
            <i
                className="fas fa-sign-out-alt"
                style={{ marginRight: '20px', cursor: 'pointer' }}
                onClick={handleLogout}  // Gọi hàm logout khi click vào icon
            ></i>
            <img alt="User Avatar" height="40" src={userAvatar} width="40" />

            <span>{userName}</span>

        </div>
    );
};

export default UserInfo;
