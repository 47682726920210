import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin';
const WarehouseEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id'); // Lấy ID từ query string
  console.log(id);
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  const [warehouseData, setWarehouseData] = useState({
    productName: '',
    productId: '', // Thêm field productId
    quantity: 0,
    stockin_date: '',
  });
  const [productOptions, setProductOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.pethaven.website/admin/get-product');
        const result = await response.json();

        if (response.ok) {
          setProductOptions(result.products);
        } else {
          console.error('Lỗi khi lấy danh sách sản phẩm:', result.message);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
      }
    };

    const getStockinById = async (id) => {
      try {
        const response = await fetch(`https://api.pethaven.website/admin/stockin/${id}`);
        const result = await response.json();

        if (response.ok) {
          console.log('reponse:', response.json);
          setWarehouseData(result);
        } else {
          showError(['Lỗi khi lấy thông tin nhập kho: ' + result.message]);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
      }
    };

    fetchProducts();
    if (id) {
      getStockinById(id);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // Chuyển đổi quantity từ chuỗi thành số nguyên
    setWarehouseData({
      ...warehouseData,
      [id]: id === 'quantity' ? parseInt(value, 10) : value
    });
  };

  const handleProductChange = (e) => {
    const selectedProduct = productOptions.find(product => product.name === e.target.value);
    setWarehouseData({
      ...warehouseData,
      productName: e.target.value,
      productId: selectedProduct ? selectedProduct.id : '', // Lưu productId
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = id
        ? await fetch(`https://api.pethaven.website/admin/stockin/update/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(warehouseData),
        })
        : await fetch('https://api.pethaven.website/admin/stockin/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(warehouseData),
        });

      const result = await response.json();
      if (response.ok) {
        showSuccess('Cập nhật thành công');
        navigate('/Warehouse');
      } else {
        alert('Đã xảy ra lỗi: ' + result.message);
      }
    } catch (error) {
      alert('Lỗi kết nối API: ' + error);
      console.log('Lỗi:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />

      <div className="content flex-grow-1">
        <div className="header">
          <div className="text-left">
            <h4 className="main-title-adminindex">{id ? 'Cập Nhật Nhập Kho' : 'Thêm Nhập Kho'}</h4>
          </div>
          <UserInfo />
        </div>

        <div className="container mt-4">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="productName" className="form-label">Chọn Sản Phẩm</label>
              <select
                id="productName"
                className="form-control form-addsp"
                value={warehouseData.productName}
                onChange={handleProductChange} // Sử dụng hàm mới
                required
              >
                <option value="">Chọn sản phẩm</option>
                {productOptions.map((product) => (
                  <option key={product.id} value={product.name}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="quantity" className="form-label">Số Lượng</label>
              <input
                type="number"
                className="form-control form-addsp"
                id="quantity"
                value={warehouseData.quantity}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="stockin_date" className="form-label">Ngày Nhập Kho</label>
              <input
                type="date"
                className="form-control form-addsp"
                id="stockin_date"
                value={warehouseData.stockin_date}
                onChange={handleInputChange}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật' : 'Nhập Kho')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WarehouseEntry;
