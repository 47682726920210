import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const OderReturnmomo = () => {
  const { orderId } = useParams(); // Lấy orderId từ URL
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    // Kiểm tra nếu có `orderId` thì xóa giỏ hàng
    if (orderId) {
      const cartCleared = localStorage.getItem('cartCleared'); // Kiểm tra trạng thái đã xóa giỏ hàng
      if (!cartCleared) {
        localStorage.removeItem('cart'); // Xóa giỏ hàng
        localStorage.setItem('cartCleared', 'true'); // Đánh dấu đã xóa giỏ hàng
      }
    }
  }, [orderId]);

  // Lấy chi tiết đơn hàng từ API
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`https://api.pethaven.website/admin/order/code/${orderId}`); // API để lấy dữ liệu
        const result = await response.json();

        if (response.ok) {
          setOrderDetails(result);
        } else {
          alert(`Không tìm thấy đơn hàng: ${result.message}`);
        }
      } catch (error) {
        alert('Lỗi kết nối API');
        console.error('Lỗi:', error);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  if (!orderDetails) {
    return (
      <div className="container mt-5">
        <h3>Đang tải dữ liệu đơn hàng...</h3>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="order-success">
        <span>Đặt hàng thành công!</span> Theo dõi đơn hàng
      </div>
      <div className="order-details">
        <h2>Chi Tiết Đơn Hàng</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Sản phẩm</th>
              <th className="text-right">Giá tiền</th>
              <th className="text-right">Số lượng</th>
              <th className="text-right">Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {orderDetails.order_details.map((item, index) => (
              <tr key={index}>
                <td>{item.product_name}</td>
                <td className="text-right">{item.price.toLocaleString()} VNĐ</td>
                <td className="text-right">{item.quantity}</td>
                <td className="text-right">{item.total_price.toLocaleString()} VNĐ</td>
              </tr>
            ))}
            <tr>
              <td colSpan="3" className="text-right">
                Tổng Số Phụ:
              </td>
              <td className="text-right">{orderDetails.total_money.toLocaleString()} VNĐ</td>
            </tr>
            <tr>
              <td>Phương Thức Thanh Toán :</td>
              <td colSpan="3" className="text-right">
                {orderDetails.payment_id === 1 ? "Momo" : orderDetails.payment_id === 2 ? "Tiền mặt" : "Khác"}
              </td>
            </tr>

            <tr>
              <td>Tổng Cộng:</td>
              <td colSpan="3" className="text-right">{orderDetails.total_money.toLocaleString()} VNĐ</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OderReturnmomo;
