import React, { useEffect, useState } from 'react';
import { fetchWarehouseItems, deleteWarehouseItem } from '../../services/Admin/ServicesService'; // Giả định bạn có service tương ứng
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin';

const Warehouse = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  const getItems = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchWarehouseItems(search, page, perPage);
      setItems(data);
      setTotalPages(last_page);
    } catch (error) {
      console.error('Error fetching warehouse items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItems();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchInput);
    setPage(1);
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Bạn có chắc chắn muốn xóa mục này?');
    if (confirmed) {
      try {
        await deleteWarehouseItem(id);
        showSuccess('Xóa thành công!');
        getItems(); // Cập nhật lại danh sách sau khi xóa
      } catch (error) {
        console.error('Error deleting warehouse item:', error);
        showError(['Có lỗi xảy ra khi xóa mục!']);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            <div className="header">
              <div className="text-left">
                <h4 className="main-title-adminindex">Danh sách nhập kho</h4>
              </div>
              <UserInfo />
            </div>

            {/* Search bar section */}
            <div className="search-bar-container d-flex align-items-center justify-content-between">
              <div className="search-bar">
                <input
                  className="form-control"
                  placeholder="Tìm tên sản phẩm"
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button className="btn btn-dark" onClick={handleSearch}>
                  <i className="fas fa-search"></i>
                </button>
              </div>

            </div>
            <Link to="/add-stockin">
              <button type="button" className="btn btn-primary">
                Nhập kho
              </button>
            </Link>
            {/* Table section */}
            <section className="table-section mt-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên Sản Phẩm</th>
                    <th>Ngày</th>
                    <th>Số Lượng</th>
                    <th>Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(page - 1) * perPage + index + 1}</td>
                      <td>{item.product_name}</td>
                      <td>{item.stockin_date}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <Link to={`/edit-stockin/?id=${item.id}`}>
                          <i className="fas fa-edit text-success" />
                        </Link>
                        <button
                          className="btn btn-link text-danger"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="fas fa-trash" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>

            {/* Pagination Controls */}
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                    Previous
                  </button>
                </li>
                <li className="page-item">
                  <span className="page-link">Page {page} of {totalPages}</span>
                </li>
                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </main>
        </div>
      </div>
    </>
  );
};

export default Warehouse;
