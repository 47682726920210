import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';  // Import moment.js

const OderHistoryReturn = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggleDropdown = () => {
        setDropdownVisible((prev) => !prev);
    };

    // Lấy thông tin user từ localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user ? user.id : null;
    const userName = user ? user.name : 'Người dùng'; // Nếu không có tên, mặc định là 'Người dùng'
    const userImage = user ? user.img : 'https://api.pethaven.website/images/user/default.jpg'; // Nếu không có ảnh, dùng ảnh mặc định

    // Fetch orders when component mounts
    useEffect(() => {
        if (!userId) {
            // Nếu không có userId, hiển thị thông báo hoặc chuyển hướng
            setError('Vui lòng đăng nhập để xem đơn hàng của bạn.');
            setLoading(false);
            return; // Dừng lại nếu không có userId
        }

        axios.get(`https://api.pethaven.website/api/orders/return/${userId}`)
            .then(response => {
                setOrders(response.data);  // Set the orders data
                setLoading(false);          // Set loading to false after data is fetched
            })
            .catch(err => {
                setError('Lỗi khi tải dữ liệu');
                setLoading(false);
            });
    }, [userId]);  // useEffect luôn được gọi khi userId thay đổi

    if (loading) {
        return (
            <div className="loading-container">
                <svg viewBox="25 25 50 50" className="spinner">
                    <circle r="20" cy="50" cx="50"></circle>
                </svg>
            </div>
        );
    }

    return (
        <>
            <div className="container center-personel">
                <div className="row profile-container">
                    {/* Sidebar */}
                    <div className="profile-sidebar">
                        <img
                            id="profile-image"
                            src={`https://api.pethaven.website/images/user/${userImage}`} // Hiển thị ảnh người dùng
                            alt="Profile Picture"
                            className="profile-picture"
                        />
                        <div className="user-info-personal">
                            <h4>{userName}</h4> {/* Hiển thị tên người dùng */}
                            <p>Đang hoạt động</p>
                            <Link to="/BookingHistory">Lịch sử đặt lịch</Link>
                            <a href="#" onClick={toggleDropdown}>
                                Đơn hàng của tôi
                            </a>
                            {dropdownVisible && (
                                <div id="order-status-dropdown">
                                    <ul>
                                        <li><Link to="/OrderHistory">Chờ xác nhận</Link></li>
                                        <li><Link to="/OderHistoryXn">Đã xác nhận</Link></li>
                                        <li><Link to="/OderHistoryShip">Giao hàng</Link></li>
                                        <li><Link to="/OderHistoryDn">Hoàn thành</Link></li>
                                        <li><Link to="/OderHistoryHuy">Đã hủy</Link></li>
                                     
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Order History Table */}
                    <div className="profile-info purchase-history-container">
                        <h4>Hoàn trả</h4>
                        <p>Tất cả đơn hàng hoàn trả sẽ ở đây</p>
                        <table className="order-history-table">
                            <thead>
                                <tr>
                                    <th>Mã Đơn Hàng</th>
                                    <th>Ngày Mua</th>
                                    <th>Tổng Tiền</th>
                                    <th>Trạng Thái</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.length > 0 ? (
                                    orders.map(order => (
                                        <tr key={order.id}>
                                            <td>{`#${order.id}`}</td>
                                            <td>{moment(order.created_at).format('DD-MM-YYYY HH:mm')}</td> {/* Định dạng ngày giờ */}
                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(order.total_money)}</td>
                                            <td>{order.status}</td>
                                            <td>
                                                <button className="btn-view-order">Xem</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">Không có đơn hàng nào hoàn trả.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OderHistoryReturn;
