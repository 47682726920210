import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component

const EditUser = () => {
  const [userData, setUserData] = useState({
    name: '',
    image: '',
    email: '',
    address: '',
    phone: '',
    gender: '',
    dob: '',
    is_action: '0'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id');

  // Fetch dữ liệu người dùng
  useEffect(() => {
    const fetchUserData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/user/${id}`);
          if (!response.ok) {
            const errorResult = await response.text();
            throw new Error('Lỗi khi lấy dữ liệu người dùng: ' + errorResult);
          }
          const result = await response.json();
          setUserData(result);
          console.log("User Data:", result); // In ra dữ liệu người dùng
        } catch (error) {
          console.error('Lỗi kết nối API:', error);
          alert(error.message);
        }
      }
    };
    fetchUserData();
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserData({
      ...userData,
      [id]: value
    });
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    if (window.confirm("Bạn có chắc chắn muốn thay đổi trạng thái không?")) {
      setUserData({
        ...userData,
        is_action: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    console.log("Updating user with ID:", id);
    console.log("Request Body:", { is_action: userData.is_action });

    try {
      const response = await fetch(`https://api.pethaven.website/admin/user/update/${id}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_action: userData.is_action })
      });

      const result = await response.json();
      console.log("Response:", result); // In ra phản hồi từ server

      if (response.ok) {
        showSuccess('Cập nhật trạng thái thành công');
        navigate('/UserAdmin');
      } else {
        showError(['Đã xảy ra lỗi: ' + result.message]);
      }
    } catch (error) {
      console.error('Lỗi kết nối API:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">Sửa Thông Tin Khách Hàng</h4>
            </div>
            <UserInfo />
          </div>

          <div className="container mt-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Tên Khách Hàng</label>
                <input type="text" className="form-control" id="name" value={userData.name || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="image" className="form-label">Hình Ảnh</label>
                <div>
                  <img id="image" alt="Customer" height={100} width={100} src={userData.image || ''} />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" value={userData.email || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">Địa Chỉ</label>
                <input type="text" className="form-control" id="address" value={userData.address || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Số Điện Thoại</label>
                <input type="tel" className="form-control" id="phone" value={userData.phone || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="gender" className="form-label">Giới Tính</label>
                <input type="text" className="form-control" id="gender" value={userData.gender || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="dob" className="form-label">Ngày Sinh</label>
                <input type="date" className="form-control" id="dob" value={userData.dob || ''} readOnly />
              </div>

              <div className="mb-3">
                <label htmlFor="is_action" className="form-label">Trạng Thái</label>
                <select className="form-control" id="is_action" value={userData.is_action} onChange={handleStatusChange}>
                  <option value="1">Tạm khoá</option>
                  <option value="0">Đang hoạt động</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang cập nhật...' : 'Cập nhật'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
