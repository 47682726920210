import { Card, CardHeader, CardContent, CardTitle } from '../../components/ui/card';
import { MyChartMonthTotal } from './MyChartMonthtotal';
import { useState } from 'react';

export function DashMonth() {
    // State để lưu năm và tháng
    const [year, setYear] = useState(2024); // Mặc định là năm 2024
    const [month, setMonth] = useState(11); // Mặc định là tháng 11

    // Tháng cho dropdown (1 đến 12)
    const months = [
        { value: 1, label: 'Tháng 1' },
        { value: 2, label: 'Tháng 2' },
        { value: 3, label: 'Tháng 3' },
        { value: 4, label: 'Tháng 4' },
        { value: 5, label: 'Tháng 5' },
        { value: 6, label: 'Tháng 6' },
        { value: 7, label: 'Tháng 7' },
        { value: 8, label: 'Tháng 8' },
        { value: 9, label: 'Tháng 9' },
        { value: 10, label: 'Tháng 10' },
        { value: 11, label: 'Tháng 11' },
        { value: 12, label: 'Tháng 12' },
    ];

    return (
        <Card>
            <CardHeader>
                <CardTitle>Doanh thu theo ngày trong tháng</CardTitle>
            </CardHeader>
            <CardContent>
                {/* Form chọn năm và tháng */}
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="year">Chọn năm: </label>
                    <input
                        type="number"
                        id="year"
                        value={year}
                        onChange={(e) => setYear(Number(e.target.value))} // Cập nhật năm khi thay đổi
                        style={{ marginLeft: '10px', marginRight: '20px' }}
                    />
                    <label htmlFor="month">Chọn tháng: </label>
                    <select
                        id="month"
                        value={month}
                        onChange={(e) => setMonth(Number(e.target.value))} // Cập nhật tháng khi thay đổi
                        style={{ marginLeft: '10px' }}
                    >
                        {months.map((m) => (
                            <option key={m.value} value={m.value}>
                                {m.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Truyền year và month vào component MyChartMonthTotal */}
                <MyChartMonthTotal year={year} month={month} />
            </CardContent>
        </Card>
    );
}
