import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { getProducts } from '../api/product';
import { CartContext } from '../context/CartContext';

const Header = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [userName, setUserName] = useState('');
  const [isSearchOverlayVisible, setSearchOverlayVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setIsLoggedIn(true);
      setUserName(user.name);
    }
  }, []);

  const handleUserIconClick = (event) => {
    event.preventDefault();
    setDropdownVisible(!isDropdownVisible);
  };

  const handleSearchIconClick = (event) => {
    event.preventDefault();
    setSearchOverlayVisible(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('search-overlay')) {
      setSearchOverlayVisible(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setDropdownVisible(false);
    navigate('/login');
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      const results = await getProducts(1, '', 9, searchQuery);
      if (results) {
        navigate(`/Product?query=${searchQuery}`);
      }
    }
    setSearchOverlayVisible(false);
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          {/* Logo */}
          <div className="logo">
            <Link className="navbar-brand" to="/">
              <img src="../img/Red & Orange Simple Petshop Logo.png" alt="Pet Planet Logo" />
            </Link>
          </div>

          {/* Nút toggler cho responsive */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Menu */}
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="col-8 navbar-nav justify-content-around">
              <li className="nav-item"><Link className="nav-link" to="/">Trang chủ</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/gioi-thieu">Giới thiệu</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/Service">Dịch vụ</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/Product">Sản phẩm</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/News">Tin tức</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/LienHe">Liên hệ</Link></li>
            </ul>

            {/* Icon Search, Cart, User */}
            <ul className="col-3 navbar-nav ml-auto icons-nav justify-content-evenly">
              {/* Search */}
              <li className="nav-item">
                <form className="search-container" onSubmit={handleSearch}>
                  <Link href="#" id="search-icon" onClick={handleSearchIconClick}>
                    <i className="fa-solid fa-magnifying-glass" style={{ color: '#111212' }}></i>
                  </Link>
                  {isSearchOverlayVisible && (
                    <div className="search-overlay" onClick={handleOverlayClick} style={{ display: 'flex' }}>
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tìm kiếm..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button type="submit" className="search-btn">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </li>

              {/* Cart */}
              <li className="nav-item user-relavity">
                <Link to="/Cart" className="nav-link">
                  <i className="fas fa-shopping-cart fa-xl"></i>
                  <span className="shopping-cart-items-count">{cartItemCount}</span>
                </Link>
              </li>

              {/* User */}
              <li className="nav-item user-relavity">
                <Link to="#" id="user-icon" className="nav-link" onClick={handleUserIconClick}>
                  <i className="fas fa-user fa-xl"></i> {isLoggedIn && <span className="user-name">{userName}</span>}
                </Link>
                {isDropdownVisible && (
                  <div className="dropdown-menu" id="login-dropdown" style={{ display: 'block' }}>
                    {isLoggedIn ? (
                      <>
                        <Link className="dropdown-item" to="/Personal">Hồ sơ người dùng</Link>
                        <button className="dropdown-item" onClick={handleLogout}>Đăng xuất</button>
                      </>
                    ) : (
                      <>
                        <Link className="dropdown-item" to="/login">Đăng nhập</Link>
                        <Link className="dropdown-item" to="/register">Đăng ký</Link>
                      </>
                    )}
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
