import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const AddProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [productData, setProductData] = useState({
        name: '',
        description: '',
        category_id: '',
        price: '',
        instock: '',
        sale_price: '',
        image: null,
        imageUrl: null
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    function showSuccess(message) {
        // Tạo một div chứa thông báo thành công
        const successContainer = document.createElement("div");
        successContainer.classList.add("notifications-container");
        
        // Thêm nội dung thông báo và nút đóng
        successContainer.innerHTML = `
     
    
    <div class="success">
    <div class="success__icon">
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
    </div>  <div class="success__title">${message}</div>
    <div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
    </div>
                
              
        `;
      
        // Thêm thông báo thành công vào body hoặc một container cụ thể
        document.body.appendChild(successContainer);
      
        // Xóa thông báo sau 3 giây
        const timeoutId = setTimeout(() => {
          successContainer.remove();
        }, 3000);
      
        // Thêm sự kiện click để đóng thông báo
        const closeButton = successContainer.querySelector(".success-close");
        if (closeButton) {
          closeButton.addEventListener("click", () => {
            clearTimeout(timeoutId);
            successContainer.remove();
          });
        }
      }
      function showError(messageList) {
        // Tạo một div chứa thông báo lỗi
        const errorContainer = document.createElement("div");
        errorContainer.classList.add("notifications-container");
    
        // Thêm nội dung thông báo và nút đóng
        errorContainer.innerHTML = `
          <div class="error-alert">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
                  <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
                </svg>
              </div>
              <div class="error-prompt-container">
                <p class="error-prompt-heading">Có lỗi xảy ra:</p>
                <div class="error-prompt-wrap">
                  <ul class="error-prompt-list" role="list">
                    ${messageList.map(msg => `<li>${msg}</li>`).join('')}
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
        `;
    
        // Thêm thông báo lỗi vào body hoặc một container cụ thể
        document.body.appendChild(errorContainer);
    
        // Xóa thông báo sau 3 giây
        const timeoutId = setTimeout(() => {
            errorContainer.remove();
        }, 3000);
    
       
    }
    const getQueryParam = (param) => {
        return new URLSearchParams(location.search).get(param);
    };

    const id = getQueryParam('id');

    useEffect(() => {
        const fetchProductData = async () => {
            if (id) {
                try {
                    const response = await fetch(`https://api.pethaven.website/admin/product/${id}`);
                    const result = await response.json();

                    if (response.ok) {
                        setProductData({
                            ...result,
                            imageUrl: result.image ? result.image : null,
                            category_id: result.category_id || ''
                        });
                    } else {
                        alert('Lỗi khi lấy dữ liệu sản phẩm: ' + result.message);
                    }
                } catch (error) {
                    alert('Lỗi kết nối API: ' + error);
                    console.error('Lỗi:', error);
                }
            }
        };

        fetchProductData();
    }, [id]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://api.pethaven.website/admin/product-category');
                const result = await response.json();

                if (response.ok) {
                    setCategories(result);
                } else {
                    console.error('Lỗi khi lấy danh mục:', result.message);
                }
            } catch (error) {
                console.error('Lỗi kết nối API:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setProductData({
            ...productData,
            [id]: value
        });
    };

    const handleFileChange = (e) => {
        setProductData({
            ...productData,
            image: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!productData.name || productData.price <= 0 || !productData.category_id) {
            showError(['Vui lòng điền đầy đủ thông tin và giá phải lớn hơn 0.']);
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('name', productData.name);
        formData.append('description', productData.description);
        formData.append('category_id', productData.category_id);
        formData.append('price', productData.price);
        formData.append('sale_price', productData.sale_price);
        if (productData.image) {
            formData.append('image', productData.image);
        }

        try {
            const url = `https://api.pethaven.website/admin/product/${id ? `update/${id}` : 'add'}`;
            const options = {
                method: id ? 'POST' : 'POST',
                headers: id
                    ? {
                        "X-HTTP-Method-Override": "PUT",
                    }
                    : {},
                body: formData,
                mode: 'cors'
            };

            const response = await fetch(url, options);
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                const text = await response.text();
                console.error('Server trả về kết quả không phải JSON:', text);
                alert('Lỗi: Server trả về không phải JSON. Chi tiết: ' + text);
                return;
            }

            const result = await response.json();
            if (response.ok) {
                showSuccess('Thêm sản phẩm thành công');
                navigate('/ProductAdmin');
            } else {
                showError(['Đã xảy ra lỗi: ' + result.message]);
            }
        } catch (error) {
            alert('Lỗi kết nối API: ' + error);
            console.log('Lỗi:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="d-flex">
                <Sidebar />
                <div className="content flex-grow-1">
                    <div className="header"></div>
                    <div className="container mt-4">
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Tên Sản Phẩm</label>
                                <input
                                    type="text"
                                    className="form-control form-addsp"
                                    id="name"
                                    placeholder="Nhập tên sản phẩm"
                                    value={productData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            {productData.imageUrl && (
                                <div className="mb-3">
                                    <label className="form-label">Ảnh Hiện Tại</label>
                                    <img
                                        src={`https://api.pethaven.website/images/products/${productData.imageUrl}`}
                                        alt="Current Product"
                                        style={{ width: '200px', height: 'auto' }}
                                    />
                                    <p>Tên hình ảnh: {productData.imageUrl}</p>
                                </div>
                            )}

                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Hình Ảnh</label>
                                <input
                                    type="file"
                                    className="form-control form-addsp"
                                    id="image"
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="category_id" className="form-label">Danh Mục</label>
                                <select
                                    className="form-select form-addsp"
                                    id="category_id"
                                    value={productData.category_id}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Chọn danh mục</option>
                                    {categories && categories.length > 0 ? (
                                        categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">Không có danh mục</option>
                                    )}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="price" className="form-label">Giá</label>
                                <input
                                    type="number"
                                    className="form-control form-addsp"
                                    id="price"
                                    placeholder="Nhập giá sản phẩm"
                                    value={productData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="sale_price" className="form-label">Giá Giảm</label>
                                <input
                                    type="number"
                                    className="form-control form-addsp"
                                    id="sale_price"
                                    placeholder="Nhập giá giảm (nếu có)"
                                    value={productData.sale_price}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Mô Tả</label>
                                <textarea
                                    className="form-control form-addsp"
                                    id="description"
                                    rows="3"
                                    value={productData.description}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>



                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Sản Phẩm' : 'Thêm Sản Phẩm')}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddProducts;
