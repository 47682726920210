import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getService } from '../../api/service';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getLatestNews } from '../../api/news';




const Home = () => {
  const [services, setServices] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [news, setNews] = useState([]);
  useEffect(() => {
    const fetchServices = async () => {
        try {
            const response = await getService();
            console.log("API response:", response);

            // Truy cập đúng mảng dịch vụ
            setServices(response.data.data.slice(0, 4)); // Lấy 4 dịch vụ đầu tiên
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };
    const fetchLatestNews = async () => {
      try {
          const response = await getLatestNews();
          console.log("API response news:", response);
          setNews(response);  // Giả sử response trả về danh sách tin tức
      } catch (error) {
          console.error("Error fetching latest news:", error);
      }
  };
  fetchLatestNews();
    fetchServices();
}, []);


const xemChiTiet = (url) => {
  setImageUrl(url);

  if (window.bootstrap && window.bootstrap.Modal) {
      const myModal = new window.bootstrap.Modal(document.getElementById('imageModal'));
      myModal.show();
  } else {
      console.error("Bootstrap Modal chưa sẵn sàng. Vui lòng kiểm tra lại!");
  }
};

  
  return (
    <>
        <main>
        <div className="container custom-section">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="custom-title">Chăm sóc<br /> thú cưng.</h2>
              <p className="custom-description">Hãy mang đến cho thú cưng của bạn <br/>sự quan tâm và chăm sóc tốt nhất.
              Dịch vụ tiện lợi, an toàn<br/> và tận tâm dành riêng cho người bạn bốn chân của bạn.</p>
              <button className="custom-button">Khám phá</button>
            </div>
            <div className="col-md-6">
              <img src="../img/slide-1.jpg" alt="Pet Care" className="img-fluid custom-rounded" />
            </div>
          </div>
        </div>
        <section className="pet-services py-5">
                    <div className="container">
                        <h5 className="text-center highlight"><i className="fa-solid fa-dog" style={{color: '#FF642F'}} /> DỊCH VỤ TẬN TÂM</h5>
                        <h2 className="text-center mb-5 main-heading">Cung cấp dịch vụ<br /> tốt nhất cho thú cưng</h2>
                        <div className="row">
                            {services.map((service, index) => (
                                <div key={index} className="col-md-3">
                                    <div className="service-card text-center p-3">
                                        <img src={`https://api.pethaven.website/images/services/${service.img}`} className="rounded-circle mb-3" alt={service.name} />
                                        <h4 className="content-service"><Link to={`/Service/${service.id}`} >{service.name}</Link></h4>
                                        <p className="content-description">{service.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
        <div className="container-fluid banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img src="../img/banner-duoi.png" alt="Pet Care Image" />
              </div>
              <div className="col-md-6 banner-content">
                <h5 className="highlight"><i className="fa-solid fa-dog" style={{color: '#FF642F'}} /> GIỚI THIỆU</h5>
                <h2 className="main-heading">Chăm sóc thú cưng an toàn và chất lượng</h2>
                <p className="content-description">“Các thành phần được sử dụng dựa trên chất lượng dinh dưỡng tốt nhất cho thú cưng và cam kết mang lại lợi ích tối đa cho sức khỏe của chúng.”</p>
                <blockquote className="content-description">
                  “Các thành phần được sử dụng dựa trên chất lượng dinh dưỡng mà chúng cung cấp và lợi nhuận của chúng.”
                </blockquote>
                <div className="row">
                  <div className="col-6">
                    <p className="content-service"><strong>Hơn 25 năm kinh nghiệm</strong></p>
                    <p className="content-description">Chúng tôi tự hào với hơn 25 năm phục vụ trong lĩnh vực chăm sóc và cung cấp sản phẩm chất lượng cho thú cưng.</p>
                  </div>
                  <div className="col-6">
                    <p className="content-service"><strong>Chứng nhận &amp; chuyên gia</strong></p>
                    <p className="content-description">Đội ngũ chuyên gia của chúng tôi được đào tạo bài bản và có chứng nhận quốc tế trong lĩnh vực chăm sóc thú cưng.</p>
                  </div>
                </div>
                <button className="cta-btn mt-3">Khám phá</button>
                <div className="dashed-border" />
              </div>
            </div>
          </div>
        </div>
        <div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-3">
            <div className="gallery-item" onClick={() => xemChiTiet('../img/pet1.jpg')}>
              <img src="../img/pet1.jpg" alt="Ảnh thú cưng" />
              <div className="overlay">
                <div className="plus-circle">+</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="gallery-item" onClick={() => xemChiTiet('../img/pet2.jpg')}>
              <img src="../img/pet2.jpg" alt="Ảnh thú cưng" />
              <div className="overlay">
                <div className="plus-circle">+</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="gallery-item" onClick={() => xemChiTiet('../img/pet3.jpg')}>
              <img src="../img/pet3.jpg" alt="Ảnh thú cưng" />
              <div className="overlay">
                <div className="plus-circle">+</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="gallery-item" onClick={() => xemChiTiet('../img/pet4.jpg')}>
              <img src="../img/pet4.jpg" alt="Ảnh thú cưng" />
              <div className="overlay">
                <div className="plus-circle">+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="modal fade" id="imageModal" tabIndex={-1} aria-labelledby="imageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img id="modalImage" src={imageUrl} alt="Ảnh chi tiết" className="img-fluid" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="container comment-service ">
          <div className="row">
            <div className="col-md-6">
              <h5 className="highlight"><i className="fa-solid fa-dog" style={{color: '#FF642F'}} /> KHÁCH HÀNG PHẢN HỒI</h5>
              <h2 className="main-heading">Khác hàng đánh giá<br /> về dịch vụ </h2>
              <div id="customerCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#customerCarousel" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                  <button type="button" data-bs-target="#customerCarousel" data-bs-slide-to={1} aria-label="Slide 2" />
                  <button type="button" data-bs-target="#customerCarousel" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>            
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="customer-review text-start">
                      <div className="d-flex align-items-center justify-content-start mb-4">
                        <img src="../img/anh-comment.jpg" alt="Customer Image" className="customer-img me-3" />
                        <div>
                          <h5 className="customer-name">Christine Eve</h5>
                          <p className="review-role">Khách hàng</p>
                          <div className="stars">
                            ★★★★★
                          </div>
                        </div>
                      </div>
                      <div className="quote-mark mb-3">“</div>
                      <p className="review-text">
                        Trang web có rất nhiều dịch vụ hữu ích và đa dạng cho thú cưng. Tôi đã sử dụng dịch vụ chăm sóc và huấn luyện và nhận được hỗ trợ tốt từ nhân viên.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="customer-review text-start">
                      <div className="d-flex align-items-center justify-content-start mb-4">
                        <img src="../img/anh-comment.jpg" alt="Customer Image" className="customer-img me-3" />
                        <div>
                          <h5 className="customer-name">John Doe</h5>
                          <p className="review-role">Chủ thú cưng</p>
                          <div className="stars">
                            ★★★★☆
                          </div>
                        </div>
                      </div>
                      <div className="quote-mark mb-3">“</div>
                      <p className="review-text">
                        Dịch vụ khá tốt, nhưng giao diện web có thể dễ sử dụng hơn. Tuy nhiên, thú cưng của tôi rất thích nơi này!
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="customer-review text-start">
                      <div className="d-flex align-items-center justify-content-start mb-4">
                        <img src="../img/anh-comment.jpg" alt="Customer Image" className="customer-img me-3" />
                        <div>
                          <h5 className="customer-name">Emily Jane</h5>
                          <p className="review-role">Người yêu thú cưng</p>
                          <div className="stars">
                            ★★★★★
                          </div>
                        </div>
                      </div>
                      <div className="quote-mark mb-3">“</div>
                      <p className="review-text">
                        Tôi đã tìm thấy dịch vụ huấn luyện chó tuyệt vời qua trang web này. Giao diện thân thiện và dễ dùng.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 side-img">
              <img src="../img/slider-02.jpg" alt="Side Image" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="container expert-container">
          <h5 className="text-center highlight"><i className="fa-solid fa-dog" style={{color: '#FF642F'}} /> GẶP GỠ CÁC CHUYÊN GIA </h5>
          <h2 className="text-center mb-5 main-heading">Gặp gỡ các chuyên gia<br /> được chứng nhận</h2>
          <div className="row justify-content-around">
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="expert-card bg-light">
                <img src="../img/nv1.jpg" alt="Thùy Dương" />
                <h3 className="expert-title">Thùy Dương</h3>
                <p className="expert-role">Nhân viên chăm sóc</p>
                <div className="social-icons">
                  <a href="#"><i className="fa-brands fa-facebook" /></a>
                  <a href="#"><i className="fa-brands fa-instagram" /></a>
                  <a href="#"><i className="fa-brands fa-twitter" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="expert-card bg-light">
                <img src="../img/nv1.jpg" alt="Văn Long" />
                <h3 className="expert-title">Văn Long</h3>
                <p className="expert-role">Nhân viên chăm sóc</p>
                <div className="social-icons">
                  <a href="#"><i className="fa-brands fa-facebook" /></a>
                  <a href="#"><i className="fa-brands fa-instagram" /></a>
                  <a href="#"><i className="fa-brands fa-twitter" /></a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="expert-card bg-light">
                <img src="../img/nv1.jpg" alt="Linh Min" />
                <h3 className="expert-title">Linh Min</h3>
                <p className="expert-role">Nhân viên chăm sóc</p>
                <div className="social-icons">
                  <a href="#"><i className="fa-brands fa-facebook" /></a>
                  <a href="#"><i className="fa-brands fa-instagram" /></a>
                  <a href="#"><i className="fa-brands fa-twitter" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-5">
          <h5 className="highlight"><i className="fa-solid fa-dog" style={{color: '#FF642F'}} /> TIN TỨC </h5>
          <h2 className=" main-heading mb-5">Tin tức mới nhất</h2>
          <div className="row">
    {(news && Array.isArray(news) && news.length > 0) ? (
      news.map((item, index) => (
        <div key={index} className="col-lg-4 col-md-6 mb-4">
          <div className="card news-card position-relative">
            <img src={`https://api.pethaven.website/images/news/${item.image}`} alt="news image" />
            <span className="badge">{item.date}</span>
            <div className="card-body">
              <p className="news-meta">
                <i className="fa-solid fa-tag" style={{color: '#008BA7'}} /> {item.category}
                <i className="fa-solid fa-comments" style={{color: '#008BA7'}} /> {item.comments} Bình luận
              </p>
              <h5 className="content-service">{item.title}</h5>
              <p className="content-description">{item.description}</p>
              <Link to={`/NewsDesciption/${item.id}`} className="read-more-btn">Đọc thêm</Link>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="col-12 text-center">Không có tin tức</div>
    )}
  </div>
        </div>
      </main>
    </>
  );
};

export default Home;
