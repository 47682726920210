import React, { useEffect, useState } from 'react';
import { fetchProducts, deleteProduct } from '../../services/Admin/ProductService';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
import UserInfo from '../../components/UserInfoAdmin'; // Import UserInfo component
const ProductAdmin = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState(''); // Biến lưu giá trị tạm thời của input
  const [search, setSearch] = useState(''); // Biến thực hiện tìm kiếm
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const getProducts = async () => {
    setLoading(true);
    try {
      const { data, last_page } = await fetchProducts(search, page, perPage);
      setProducts(data);
      console.log('datapro',data);
      setTotalPages(last_page);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [search, page, perPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchInput); // Cập nhật giá trị tìm kiếm từ input và reset về trang đầu tiên
    setPage(1);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa sản phẩm này?')) {
      try {
        await deleteProduct(id);
        getProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  if (loading) return <div className="loading-container">
    <svg viewBox="25 25 50 50" className="spinner">
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
  </div>;
  if (products.length === 0) return <div>Không có sản phẩm nào.</div>;

  return (
    <div className="d-flex">

      <Sidebar /> {/* Sử dụng Sidebar component */}

      <div className="content flex-grow-1">
        <div className="header">
          <div className="text-left">
            <h4 className="main-title-adminindex"> Quản lý sản phẩm</h4>
          </div>

          <UserInfo />

        </div>
        <div className="search-bar-container">
          <div className="search-bar">
            <input
              className="form-control form-addsp"
              placeholder="Tìm mã sản phẩm"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button className="btn btn-dark" onClick={handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <form className="form-inline text-left">
          <Link to="/add-product">
            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#addProductModal">
              Thêm sản phẩm
            </button>
          </Link>
        </form>
        
        <div className="table-container">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>STT</th>
                <th>Ảnh</th>
                <th>Tên</th>
                <th>Kho</th>
                <th>Giá</th>
                <th>Giá Giảm</th>
                <th>Danh Mục</th>
                <th>Sửa</th>
                <th>Xóa</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img alt={`Product Image ${index + 1}`} height={50} src={`https://api.pethaven.website/images/products/${product.image}`} width={50} />
                  </td>
                  <td>{product.name}</td>
                  <td>
                    <span className={`status-dot ${product.stock_quantity > 10
                      ? 'green'
                      : product.stock_quantity > 0
                        ? 'yellow'
                        : 'red'
                      }`}></span>
                  </td>

                  <td>  {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.price)} </td>
                  <td>  {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.sale_price)}</td>
                  <td>{product.category_name}</td> {/* Cập nhật để lấy tên danh mục */}
                  <td>
                    <Link to={`/edit-product/?id=${product.id}`} onClick={() => console.log(`Editing product ${product.id}`)}>
                      <i className="fas fa-edit text-success" />
                    </Link>
                  </td>
                  <td>
                    <i className="fas fa-trash-alt text-danger" onClick={() => handleDelete(product.id)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination Controls */}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                  Previous
                </button>
              </li>
              <li className="page-item">
                <span className="page-link">Page {page} of {totalPages}</span>
              </li>
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ProductAdmin;
