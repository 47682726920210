import React, { useState } from 'react';
import axios from 'axios';

const LienHe = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false); // Hiển thị trạng thái khi gửi
  const [responseMessage, setResponseMessage] = useState(null); // Kết quả gửi
  const [errorMessage, setErrorMessage] = useState(null); // Lỗi nếu có
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

    // Thêm sự kiện click để đóng thông báo khi nhấn vào nút "X"
    const closeButton = errorContainer.querySelector(".close-btn");
    closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId); // Hủy timeout nếu người dùng tự đóng
        errorContainer.remove();
    });
}
  // Cập nhật dữ liệu khi người dùng nhập
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Xử lý khi gửi form
  const handleSubmit = async (e) => {
    e.preventDefault(); // Ngăn chặn refresh trang
    setLoading(true);
    setResponseMessage(null);
    setErrorMessage(null);

    try {
      const response = await axios.post('https://api.pethaven.website/api/contact', formData, {
        headers: { 'Content-Type': 'application/json' },
      });

      setResponseMessage(response.data.message || 'Gửi liên hệ thành công!');
      showSuccess("Bạn đã liên hệ thành công")
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } catch (error) {
      showError(['Có lỗi xảy ra khi gửi liên hệ.']);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        {/* Phần banner */}
        <div className="banner-service">
          <div className="banner-service-text">
            <h1 className="main-title">Liên hệ</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Trang Chủ</a></li>
                <li className="breadcrumb-item active-pro" aria-current="page">Liên hệ</li>
              </ol>
            </nav>
          </div>
        </div>

        {/* Phần form liên hệ */}
        <div className="container distance-contact">
          <div className="row">
            <div className="col-md-12 mb-6">
              <div className="contact-header">
                <h2 className="text-center main-heading">Liên hệ với chuyên gia để <br />được tư vấn</h2>
              </div>
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <input
                        type="text"
                        className="form-control-contact"
                        id="name"
                        placeholder="Nhập họ tên"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <input
                        type="email"
                        className="form-control-contact"
                        id="email"
                        placeholder="Nhập địa chỉ email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <textarea
                      className="form-control-contact-textarea"
                      id="message"
                      rows={4}
                      placeholder="Nhập nội dung"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                  <button type="submit" className="btn btn-warning w-30" disabled={loading}>
                    {loading ? 'Đang gửi...' : 'Gửi thông tin'}
                  </button>
                </form>
              </div>
           
            </div>
          </div>

          {/* Phần bản đồ và thông tin liên hệ */}
          <div className="map-container">
            <iframe
              className="map-embed"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31347.553298010487!2d106.59103797910154!3d10.853782900000011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752b6c59ba4c97%3A0x535e784068f1558b!2zVHLGsOG7nW5nIENhbyDEkeG6s25nIEZQVCBQb2x5dGVjaG5pYw!5e0!3m2!1svi!2s!4v1727518173489!5m2!1svi!2s"
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen
            />
            <div className="contact-info-box">
              <h4>Thông tin liên hệ</h4>
              <p><i className="fas fa-location-dot" /> 1073/23 Cách Mạng Tháng 8, P.7, Q. Tân Bình, TPHCM</p>
              <p><i className="fas fa-phone" /> (+84) 0313728397</p>
              <p><i className="fas fa-clock" /> Thứ 2 - Thứ 6: 09:00 - 18:00</p>
              <p><i className="fas fa-envelope" /> info@themona.global</p>
              <div className="contact-icons">
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-facebook-f" /></a>
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-twitter" /></a>
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-pinterest-p" /></a>
                <a href="#" className="btn btn-outline-secondary btn-sm"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>

          {/* Phần đăng ký nhận tin tức */}
          <div className="subscribe-container">
            <div className="col-md-12 mb-0.5">
              <div className="row">
                <div className="col-md-6">
                  <i className="fas fa-envelope" />
                  <h4>Đăng ký ngay để được nhận tin tức mới nhất.</h4>
                </div>
                <div className="col-md-6">
                  <form className="subscribe-form">
                    <input type="email" placeholder="Địa chỉ email..." required />
                    <button type="submit"><i className="fas fa-paper-plane" /></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LienHe;
