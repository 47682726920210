import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar'; // Import Sidebar component
const EditOrderAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState(null);
  const [status, setStatus] = useState('');
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  // Các trạng thái hợp lệ và trạng thái kế tiếp
  const statusFlow = {
    pending: ['prepare', 'cancle', 'return'],
    prepare: ['shipping', 'cancle', 'return'],
    shipping: ['success', 'cancle', 'return'],
    success: ['return'], // Có thể trả về sau khi thành công
    cancle: [], // Không thể thay đổi từ trạng thái "cancel"

  };

  // Lấy orderId từ URL
  const getQueryParam = (param) => new URLSearchParams(location.search).get(param);
  const orderId = getQueryParam('id');

  // Lấy dữ liệu đơn hàng theo orderId
  useEffect(() => {
    const fetchOrderData = async () => {
      if (orderId) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/order/${orderId}`);
          const result = await response.json();

          if (response.ok) {
            setOrderData(result);
            setStatus(result.status);
          } else {
            alert(`Lỗi khi lấy dữ liệu đơn hàng: ${result.message}`);
          }
        } catch (error) {
          alert(`Lỗi kết nối API: ${error}`);
          console.error('Lỗi:', error);
        }
      }
    };

    fetchOrderData();
  }, [orderId]);

  // Xử lý cập nhật trạng thái
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.pethaven.website/admin/order/update/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });

      const result = await response.json();
      if (response.ok) {
        showSuccess('Cập nhật trạng thái thành công');
        navigate('/OderAdmin');
      } else {
        showError([`Lỗi cập nhật trạng thái: ${result.message}`]);
      }
    } catch (error) {
      alert(`Lỗi kết nối API: ${error}`);
      console.error('Lỗi:', error);
    }
  };

  // Lọc trạng thái cho phép dựa trên trạng thái hiện tại
  const getAvailableStatuses = () => {
    return Object.keys(statusFlow).map((key) => {
      const statusLabels = {
        pending: 'Chờ xử lí',
        prepare: 'Đã xác nhận',
        shipping: 'Đang giao hàng',
        success: 'Thành công',
        cancle: 'Đã hủy',

      };

      return {
        value: key,
        label: statusLabels[key], // Hiển thị tiếng Việt cho các trạng thái
        disabled: !statusFlow[status].includes(key), // Disable if not allowed
      };
    });
  };

  return (
    <div className="d-flex">
      <Sidebar />

      <div className="content flex-grow-1">
        <div className="header">
          <h4 className="main-title-adminindex">Sửa Trạng Thái Đơn Hàng</h4>
        </div>

        <div className="container mt-4">
          <form onSubmit={handleSubmit}>
            {orderData && (
              <>
                <div className="mb-3">
                  <label className="form-label">Tên Khách Hàng</label>
                  <input type="text" className="form-control" value={orderData.user_fullname} readOnly />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input type="email" className="form-control" value={orderData.user_email} readOnly />
                </div>
                <div className="mb-3">
                  <label className="form-label">Số Điện Thoại</label>
                  <input type="tel" className="form-control" value={orderData.user_phone} readOnly />
                </div>

                <div className="mb-3">
                  <label className="form-label">Tổng Tiền</label>
                  <input type="text" className="form-control" value={`${orderData.total_money}đ`} readOnly />
                </div>

                <div className="mb-3">
                  <label className="form-label">Trạng Thái</label>
                  <select
                    className="form-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    {getAvailableStatuses().map(({ value, label, disabled }) => (
                      <option key={value} value={value} disabled={disabled}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Bảng chi tiết đơn hàng */}
                <div className="table-responsive mt-4">
                  <table className="table table-bordered">
                    <thead className="table-primary">
                      <tr>
                        <th>Tên sản phẩm</th>
                        <th>Giá tiền</th>
                        <th>Số lượng</th>
                        <th>Thành tiền</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.order_details.map((product, index) => (
                        <tr key={index}>
                          <td>{product.product_name}</td>
                          <td>{product.price}đ</td>
                          <td>{product.quantity}</td>
                          <td>{product.total_price.toLocaleString()}đ</td>
                        </tr>
                      ))}
                      <tr>
                        <th>Tổng tiền</th>
                        <td colSpan="3" style={{ textAlign: 'right' }}>
                          {`${orderData.total_money}đ`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <button type="submit" className="btn btn-primary mt-3">
                  Lưu Thay Đổi
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditOrderAdmin;

