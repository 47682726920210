import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SliderBar';
import UserInfo from '../../components/UserInfoAdmin';

const AddNewsAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const id = getQueryParam('id');
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  const [newsData, setNewsData] = useState({
    title: '',
    description1: '',
    description2: '',
    content: '',
    categorynew_id: '',
    image: null,
    imageUrl: null,
    user_id: null
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchNewsData = async () => {
      if (id) {
        try {
          const response = await fetch(`https://api.pethaven.website/admin/news/${id}`);
          const result = await response.json();
          if (response.ok) {
            setNewsData({
              ...result,
              imageUrl: result.image ? `https://api.pethaven.website/images/news/${result.image}` : null,
              categorynew_id: result.categorynew_id || '',
              user_id: result.user_id
            });
          } else {
            showError(['Lỗi khi lấy dữ liệu tin tức: ' + result.message]);
          }
        } catch (error) {
          showError(['Lỗi kết nối API: ' + error]);
        }
      }
    };

    fetchNewsData();
  }, [id]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.pethaven.website/admin/new-category');
        const result = await response.json();
        if (response.ok) {
          setCategories(result);
        } else {
          console.error('Lỗi khi lấy danh mục:', result.message);
        }
      } catch (error) {
        console.error('Lỗi kết nối API:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setNewsData({
      ...newsData,
      [id]: value
    });
  };

  const handleFileChange = (e) => {
    setNewsData({
      ...newsData,
      image: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newsData.title || !newsData.categorynew_id) {
      showError(['Vui lòng điền đầy đủ thông tin.']);
      return;
    }

    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const updatedNewsData = { ...newsData, user_id: user.id };
    const formData = new FormData();
    formData.append('title', updatedNewsData.title);
    formData.append('description1', updatedNewsData.description1);
    formData.append('description2', updatedNewsData.description2);
    formData.append('content', updatedNewsData.content);
    formData.append('categorynew_id', updatedNewsData.categorynew_id);
    formData.append('user_id', updatedNewsData.user_id);
    if (updatedNewsData.image) {
      formData.append('image', updatedNewsData.image);
    }

    try {
      const url = `https://api.pethaven.website/admin/news/${id ? `update/${id}` : 'add'}`;
      const options = {
        method: id ? 'POST' : 'POST',
        headers: id
          ? {
            "X-HTTP-Method-Override": "PUT",
          }
          : {},
        body: formData,
        mode: 'cors'
      };

      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('Server trả về kết quả không phải JSON:', text);
        alert('Lỗi: Server trả về không phải JSON. Chi tiết: ' + text);
        return;
      }

      const result = await response.json();
      if (response.ok) {
        showSuccess('Thêm tin tức thành công');
        navigate('/NewAdmin');
      } else {
        showError(['Đã xảy ra lỗi: ' + result.message]);
      }
    } catch (error) {
      alert('Lỗi kết nối API: ' + error);
      console.log('Lỗi:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className="content flex-grow-1">
          <div className="header">
            <div className="text-left">
              <h4 className="main-title-adminindex">{id ? 'Cập Nhật Tin Tức' : 'Thêm Tin Tức'}</h4>
            </div>
            <UserInfo />
          </div>
          <div className="container mt-4">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="mb-3">
                <label htmlFor="title" className="form-label">Tên Tin Tức</label>
                <input
                  type="text"
                  className="form-control form-addsp"
                  id="title"
                  placeholder="Nhập tên tin tức"
                  value={newsData.title}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {newsData.imageUrl && (
                <div className="mb-3">
                  <label className="form-label">Ảnh Hiện Tại</label>
                  <img src={newsData.imageUrl} alt="Current News" style={{ width: '200px', height: 'auto' }} />
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="image" className="form-label">Hình Ảnh</label>
                <input
                  type="file"
                  className="form-control form-addsp"
                  id="image"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="categorynew_id" className="form-label">Thể Loại</label>
                <select
                  className="form-select form-addsp"
                  id="categorynew_id"
                  value={newsData.categorynew_id}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Chọn thể loại</option>
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))
                  ) : (
                    <option value="">Không có thể loại</option>
                  )}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="description1" className="form-label">Mô Tả 1</label>
                <textarea
                  className="form-control form-addsp"
                  id="description1"
                  rows={3}
                  value={newsData.description1}
                  onChange={handleInputChange}
                  placeholder="Nhập mô tả 1"
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="description2" className="form-label">Mô Tả 2</label>
                <textarea
                  className="form-control form-addsp"
                  id="description2"
                  rows={3}
                  value={newsData.description2}
                  onChange={handleInputChange}
                  placeholder="Nhập mô tả 2"
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="content" className="form-label">Nội Dung</label>
                <textarea
                  className="form-control form-addsp"
                  id="content"
                  rows={5}
                  value={newsData.content}
                  onChange={handleInputChange}
                  placeholder="Nhập nội dung"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                {isSubmitting ? 'Đang xử lý...' : (id ? 'Cập Nhật Tin Tức' : 'Thêm Tin Tức')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewsAdmin;
