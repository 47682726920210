import React, { useState, useEffect } from 'react';

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [error, setError] = useState(null);
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

    // Thêm sự kiện click để đóng thông báo khi nhấn vào nút "X"
    const closeButton = errorContainer.querySelector(".close-btn");
    closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId); // Hủy timeout nếu người dùng tự đóng
        errorContainer.remove();
    });
}
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('user'))?.id;
  
    if (!userId) {
      setError('Bạn cần đăng nhập để xem lịch sử đặt lịch.');
      return;
    }
  
    fetch(`https://api.pethaven.website/api/booking-history?user_id=${userId}`)
      .then(response => {
        if (!response.ok) throw new Error('Lỗi khi lấy lịch sử đặt lịch');
        return response.json();
      })
      .then(data => {
        if (data.status === 'success') {
          // Thêm trạng thái isCancelled vào mỗi booking
          const updatedBookingHistory = data.data.map(booking => ({
            ...booking,
            isCancelled: booking.status === 'canceled'
          }));
          setBookingHistory(updatedBookingHistory);
        } else {
          setError(data.message || 'Lỗi khi lấy lịch sử đặt lịch');
        }
      })
      .catch(err => setError(err.message || 'Lỗi kết nối tới API'));
  }, []);

  const handleCancelBooking = (bookingId) => {
    if (window.confirm("Bạn chắc chắn muốn hủy đặt lịch này không?")) {
      fetch(`https://api.pethaven.website/api/booking/${bookingId}/cancel`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            showSuccess('Bạn đã hủy thành công dịch vụ');
            
            // Cập nhật bookingHistory để đánh dấu booking đã hủy
            setBookingHistory(prevHistory =>
              prevHistory.map(booking =>
                booking.id === bookingId
                  ? { ...booking, isCancelled: true } // Đánh dấu booking là đã hủy
                  : booking
              )
            );
          } else {
            showError("Có lỗi xảy ra!");
          }
        })
        .catch(error => console.error("Error:", error));
    }
  };
  const translateStatus = (status) => {
    switch (status) {
      case "pending":
        return "Đang chờ xác nhận";
      case "confirmed":
        return "Đã xác nhận";
      case "success":
        return "Hoàn thành";
      case "canceled":
        return "Đã hủy";
      default:
        return status; // Giữ nguyên nếu không tìm thấy
    }
  };
  return (
    <>
      <div className="booking-container">
        <div className="booking-history-container">
          <h4>Đặt Lịch Cho Thú Cưng</h4>
          <p>Xem và quản lý các lịch đặt của thú cưng</p>
          {error && <p className="text-danger">{error}</p>}
          <table className="booking-table">
            <thead>
              <tr>
                <th>ID Đơn Hàng</th>
                <th>Ngày Đặt Lịch</th>
                <th>Giờ Đặt Lịch</th> {/* Thêm cột Giờ Đặt Lịch */}
                <th>Tổng Tiền</th>
                <th>Trạng Thái</th>
                <th>Thú Cưng</th>
                <th>Số Điện Thoại</th>
                <th>Email</th>
                <th>Dịch Vụ</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {bookingHistory.length > 0 ? (
                bookingHistory.map((booking, index) => (
                  <tr key={index}>
                    <td>#{booking.id}</td>
                    <td>{new Date(booking.booking_date).toLocaleDateString()}</td>
                    {/* Cập nhật giờ hiển thị */}
                    <td>{booking.booking_time || 'N/A'}</td>
                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(booking.total_price)} </td>
                    <td>{translateStatus(booking.status)}</td>
                    <td>{booking.pet?.name || 'N/A'}</td>
                    <td>{booking.phone}</td>
                    <td>{booking.email}</td>
                    <td>{booking.service?.name || 'N/A'}</td>
                    <td>
  {new Date(booking.booking_date) > new Date() && 
  !booking.isCancelled && 
  booking.status === "pending" ? (
    <button onClick={() => handleCancelBooking(booking.id)} className="btn-cancel">
      Hủy
    </button>
  ) : (
    <span>{booking.isCancelled || booking.status === "canceled" ? "Đã hủy" : "Không thể hủy"}</span>
  )}
</td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: 'center' }}>
                    Không có lịch sử đặt lịch.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default BookingHistory;
