import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getService, getServiceById } from '../../api/service'; // Giả sử API nằm trong api.js

const ServiceDetail = () => {
  const { id } = useParams(); // Lấy ID từ URL
  const [service, setService] = useState(null); // State để lưu thông tin dịch vụ
  const [servicesnew, setServicesnew] = useState([]);
  const [loading, setLoading] = useState(true); // Trạng thái tải dữ liệu
  const [error, setError] = useState(null); // Trạng thái lỗi nếu có
  useEffect(() => {
    const fetchServices = async () => {
        try {
            const response = await getService();
            console.log("API response:", response);

            // Truy cập đúng mảng dịch vụ
            setServicesnew(response.data.data.slice(0, 5)); // Lấy 4 dịch vụ đầu tiên
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };

    fetchServices();
}, []);

  // Gọi API để lấy chi tiết dịch vụ khi component được mount
  useEffect(() => {
    const fetchService = async () => {
      try {
        const data = await getServiceById(id); // Gọi API lấy dịch vụ theo ID
        setService(data.data); // Lưu dữ liệu vào state
      } catch (error) {
        setError('Không tìm thấy dịch vụ này.');
      } finally {
        setLoading(false); // Dừng trạng thái tải
      }
    };

    fetchService();
  }, [id]); // Chạy lại khi ID thay đổi

  if (loading) return  <div className="loading-container">
  <svg viewBox="25 25 50 50" className="spinner">
    <circle r="20" cy="50" cx="50"></circle>
  </svg>
</div>; // Hiển thị khi đang tải
  if (error) return <p>{error}</p>; // Hiển thị nếu có lỗi

  return (
    <>
  
        <div className="banner-service">
          <div className="banner-service-text">
            <h1 className="main-title">Dịch vụ</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link href="#">Trang Chủ</Link></li>
                <li className="breadcrumb-item active-pro" aria-current="page">Chi tiết dịch vụ</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="container service-description-margin">
  <div className=" row-flex"> 
    <div className="col-lg-4 col-md-12 sidebar"> 
    <div className="sidebar">
                <div className="accordion" id="accordionExample">
                {servicesnew.map((service, index) => (
                                 <div className="accordion-item" style={{borderRadius: '22px'}}>
                                 <h2 className="accordion-header" id="headingOne">
                                  <Link to={`/Service/${service.id}`}>
                                   <button aria-controls="collapseOne" aria-expanded="true" className="accordion-button collapsed" data-bs-target="#collapseOne" data-bs-toggle="collapse" type="button" style={{borderRadius: '22px'}}>
                                    {service.name}
                                   </button>
                                   </Link>
                                 </h2>
                               </div>
                            ))}
                 
             
                </div>
              </div>
              <div className="contact-box mt-4 align-content-center">
                <i className="fas fa-phone-alt">
                </i>
                <h4 className="main-heading-contact">
                  Liên hệ
                </h4>
                <p className="main-heading-contact-text2">
                  Gọi cho chúng tôi:
                </p>
                <p className="fw-bold">
                  (+84) 0315728397
                </p>
              </div>
    </div>
    <div className="col-lg-8 col-md-12 main-content-dv-description"> {/* Nội dung chính */}
    <img alt={service.name} className="img-fluid img-radius"  src={`https://api.pethaven.website/images/services/${service.imgdetail}`} width={800}  />
              <h2 className="mt-4 main-heading-service-description">
               {service?.name}
              </h2>
              <p className="custom-description-service-item">
              {service?.description}
              </p>
              <p className="highlight-text">
                Chúng tôi luôn đảm bảo cung cấp những dịch vụ chăm sóc thú cưng tận tâm và chuyên nghiệp nhất.
              </p>
              <p className="custom-description-service-item">
                <b>Không gian rộng rãi và thoải mái:</b> Mỗi bé mèo sẽ có một khu vực riêng tư, rộng rãi và sạch sẽ, giúp mèo cưng cảm thấy an toàn và thoải mái trong suốt thời gian lưu trú.<br /><br />
                <b>Chế độ dinh dưỡng phù hợp:</b> Dịch vụ cung cấp các bữa ăn dinh dưỡng theo yêu cầu, đảm bảo mèo được ăn đúng giờ và đúng loại thức ăn quen thuộc, giúp mèo không bị căng thẳng khi xa chủ.<br /><br />
                <b> Hoạt động vui chơi và giải trí:</b> Các bé mèo sẽ được tham gia vào các hoạt động chơi đùa hằng ngày để giữ cho chúng luôn năng động, vui vẻ, và không cảm thấy cô đơn.<br /><br />
                <b>Theo dõi sức khỏe thường xuyên:</b> Mèo sẽ được kiểm tra sức khỏe định kỳ, theo dõi sát sao các dấu hiệu bất thường, và có sẵn các dịch vụ y tế nếu cần thiết.<br /><br />
                <b>Cập nhật tình hình cho chủ:</b> Dịch vụ sẽ cung cấp báo cáo thường xuyên bằng hình ảnh hoặc video để bạn yên tâm về tình trạng của mèo trong thời gian bạn vắng nhà.
              </p>
              <div className="icon-box">
                <i className="fas fa-paw">
                </i>
                <p className="custom-description-service-item">
                  Đặt lịch chăm sóc thú cưng của bạn ngay hôm nay để nhận được những ưu đãi đặc biệt từ dịch vụ của chúng tôi.
                </p>
              </div>
              <div className="icon-box">
                <i className="fas fa-heart">
                </i>
                <p className="custom-description-service-item">
                  Tận hưởng sự an tâm tuyệt đối với dịch vụ giám sát và báo cáo tình trạng thú cưng thường xuyên.
                </p>
              </div>
              <p className="custom-description-service-item">
                Hãy để chúng tôi giúp bạn chăm sóc thú cưng của mình một cách tốt nhất. Dịch vụ của chúng tôi luôn mang đến sự thoải mái, an toàn và niềm vui cho thú cưng của bạn.
              </p>
              <Link to={`/ServiceBooking?serviceId=${service.id}`}>
  <button type="submit" className="btn-service-description">Đặt lịch tại đây</button>
</Link>

    </div>
  </div>
</div>




       
      
    </>
  );
};

export default ServiceDetail;
