import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, login } from '../../services/AthSevices';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'; // Import GoogleOAuthProvider and GoogleLogin

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '', remember: false });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { setUser, user } = useUser();
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

    // Thêm sự kiện click để đóng thông báo khi nhấn vào nút "X"
    const closeButton = errorContainer.querySelector(".close-btn");
    closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId); // Hủy timeout nếu người dùng tự đóng
        errorContainer.remove();
    });
}
  useEffect(() => {
    if (user) {
      navigate(user.role === 'admin' ? '/Dashboard' : '/');
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await login(formData);
      console.log('Full server response:', response);

      if (response.success) {
        const user = response.user;
        const accessToken = response.access_token;

        if (formData.remember) {
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('access_token', accessToken);
        } else {
          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('access_token', accessToken);
        }
        setUser(user);
        
     
        showSuccess('Bạn đã đăng nhập thành công!');
        setTimeout(() => {
         
          navigate(user.role === 'admin' ? '/Dashboard' : '/');
        }, 3500); // Thời gian delay (không bắt buộc)
      } else {
        // Sử dụng hàm showError
        showError(['Đăng nhập không thành công.', 'Vui lòng kiểm tra thông tin đăng nhập.']);
      }
    } catch (error) {
      console.error('Unexpected error during login:', error);
    
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await forgotPassword(formData.email);
      localStorage.setItem('email', formData.email);
      if (response.success) {
        showSuccess('Chúng tôi gửi mã OTP vào gmail của bạn vui lòng check mail');
        navigate('/Otp'); // Chuyển hướng tới trang nhập OTP
      } else {
        showError('Lỗi khi gửi yêu cầu quên mật khẩu.');
      }
    } catch (error) {
      console.error(error);
      showError('Lỗi khi gửi yêu cầu quên mật khẩu.');
    }
  };

  const handleLoginSuccess = async (response) => {
    const token = response.credential;  // Google id_token

    try {
      const apiResponse = await fetch('https://api.pethaven.website/auth/google/callback', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Gửi token Google vào header
        }
      });
      const data = await apiResponse.json();
      
      if (data.success) {
        // Lưu access token vào localStorage
        localStorage.setItem('access_token', data.access_token);
        // Lưu thông tin người dùng vào state hoặc context
        console.log('Đăng nhập thành công', data.user);
      } else {
        console.error('Đăng nhập thất bại', data.message);
      }
    } catch (error) {
      console.error('Lỗi khi kết nối với API:', error);
    }
  };
  

  const handleGoogleLoginFailure = (error) => {
    console.log('Google login failed:', error);
    setErrorMessage('Đăng nhập Google thất bại. Vui lòng thử lại.');
  };

  return (
    <div className="wrap">
      <div className="containerLogin" id="container">
        <div className="form-container sign-in-container">
          <form onSubmit={handleLogin} className='formLogin'>
            <h1 className="headH1">ĐĂNG NHẬP</h1>
            <div className="social-container">
              {/* <a href="#" className="social"><i className="fab fa-facebook-f"></i></a> */}
              {/* Đăng nhập bằng Google */}
            
              {/* <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a> */}
            </div>
            <input
              type="email"
              name="email"
              className="username"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              className="username"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <Link onClick={handleForgotPassword} className="social">Quên mật khẩu?</Link>
            <button type="submit" className="buttonLogin">ĐĂNG NHẬP</button>
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay-Login"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
