import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Personal = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    birthdate: '',
    img: '',
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false); // Trạng thái hiển thị dropdown
  function showSuccess(message) {
    // Tạo một div chứa thông báo thành công
    const successContainer = document.createElement("div");
    successContainer.classList.add("notifications-container");
    
    // Thêm nội dung thông báo và nút đóng
    successContainer.innerHTML = `
 

<div class="success">
<div class="success__icon">
<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z" fill="#393a37" fill-rule="evenodd"></path></svg>
</div>  <div class="success__title">${message}</div>
<div class="success__close"><svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg></div>
</div>
            
          
    `;
  
    // Thêm thông báo thành công vào body hoặc một container cụ thể
    document.body.appendChild(successContainer);
  
    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
      successContainer.remove();
    }, 3000);
  
    // Thêm sự kiện click để đóng thông báo
    const closeButton = successContainer.querySelector(".success-close");
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        clearTimeout(timeoutId);
        successContainer.remove();
      });
    }
  }
  function showError(messageList) {
    // Tạo một div chứa thông báo lỗi
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("notifications-container");

    // Thêm nội dung thông báo và nút đóng
    errorContainer.innerHTML = `
      <div class="error-alert">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="error-svg">
              <path clip-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="error-prompt-container">
            <p class="error-prompt-heading">Có lỗi xảy ra:</p>
            <div class="error-prompt-wrap">
              <ul class="error-prompt-list" role="list">
                ${messageList.map(msg => `<li>${msg}</li>`).join('')}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    `;

    // Thêm thông báo lỗi vào body hoặc một container cụ thể
    document.body.appendChild(errorContainer);

    // Xóa thông báo sau 3 giây
    const timeoutId = setTimeout(() => {
        errorContainer.remove();
    }, 3000);

   
}
  // Lấy thông tin user khi component được mount
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('user'))?.id;

    if (userId) {
      const getUserProfile = async () => {
        try {
          const response = await fetch(`https://api.pethaven.website/api/user/profile/${userId}`);
          const profileData = await response.json();
          setUserData(profileData);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      };
      getUserProfile();
    } else {
      console.error("User ID is missing in localStorage.");
    }
  }, []);

  // Toggle dropdown "Đơn hàng của tôi"
  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  // Submit form cập nhật user
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = JSON.parse(localStorage.getItem('user'))?.id;

    if (!userId) {
      console.error("User ID is missing or undefined.");
      return;
    }

    const formData = new FormData();
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('phone', userData.phone);
    formData.append('gender', userData.gender);
    formData.append('birthdate', userData.birthdate);

    if (selectedImage) {
      formData.append('img', selectedImage);
    }

    try {
      const response = await fetch(`https://api.pethaven.website/api/user/profile/${userId}`, {
        method: 'POST',
        headers: {
          "X-HTTP-Method-Override": "PUT",
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);
        throw new Error(`Failed to update user profile: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();

      if (result.user) {
        setUserData(result.user);
        localStorage.setItem('user', JSON.stringify(result.user));
        showSuccess('Thay đổi hồ sơ thành công');
      } else {
        showSuccess('Thay đổi hồ sơ thành công');
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      showError(['Thay đổi hồ sơ thất bại']);
    }
  };

  // Handle thay đổi giá trị input
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'img' && files) {
      setSelectedImage(files[0]);
      setUserData((prevData) => ({
        ...prevData,
        img: URL.createObjectURL(files[0]),
      }));
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <div className="container center-personel">
      <div className="row profile-container">
        {/* Sidebar */}
        <div className="profile-sidebar">
          <img
            id="profile-image"
            src={`https://api.pethaven.website/images/user/${userData.img}`}
            alt="Profile Picture"
            className="profile-picture"
          />
          <div className="user-info-personal">
            <h4>{userData.name || 'Người dùng'}</h4>
            <p>Đang hoạt động</p>
            <Link to="/BookingHistory">Lịch sử đặt lịch</Link>
            <a href="#" onClick={toggleDropdown}>
              Đơn hàng của tôi
            </a>
            {dropdownVisible && (
              <div id="order-status-dropdown">
                <ul>
                <li><Link to="/OrderHistory">Chờ xác nhận</Link></li>
                  <li><Link to="/OderHistoryXn">Đã xác nhận</Link></li>
                  <li><Link to="/OderHistoryShip">Giao hàng</Link></li>
                  <li><Link to="/OderHistoryDn">Hoàn thành</Link></li>
                  <li><Link to="/OderHistoryHuy">Đã hủy</Link></li>

                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Form thông tin */}
        <div className="profile-info">
          <h4>Thông Tin Cá Nhân</h4>
          <form id="profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Tên</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={userData.name || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={userData.email || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Số điện thoại</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={userData.phone || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Giới tính</label>
              <div>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={userData.gender === 'male'}
                  onChange={handleChange}
                /> Nam
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  className="ml-3"
                  checked={userData.gender === 'female'}
                  onChange={handleChange}
                /> Nữ
                <input
                  type="radio"
                  name="gender"
                  id="other"
                  value="other"
                  className="ml-3"
                  checked={userData.gender === 'other'}
                  onChange={handleChange}
                /> Khác
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="birthdate">Ngày sinh</label>
              <input
                type="date"
                className="form-control"
                id="birthdate"
                name="birthdate"
                value={userData.birthdate || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="img">Ảnh đại diện</label>
              <input
                type="file"
                className="form-control"
                id="img"
                name="img"
                accept="image/*"
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="btn btn-danger">Lưu</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Personal;
